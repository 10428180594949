/**
 * Given a asset key, returns the complete asset URL
 */
export const useApiAsset = (
  key: string | null,
  options: {
    placeholder: 'square' | 'banner'
  } = {
    placeholder: 'square'
  }
) => {
  if (!key) return useImagePlaceholder(options.placeholder)

  const config = useRuntimeConfig()

  return [config.public.apiBaseURL, 'assets', key].join('/')
}
