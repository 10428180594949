/**
 * State of the player
 */

const isPlaying: Ref<boolean> = ref(false) // Playing state
const isLive: Ref<boolean> = ref(false) // Live is playing or not; whether there's a program or a title

const isEnabled: Ref<boolean> = ref(false)

const loading: Ref<number> = ref(0) // Deprecated ?

const live: Ref<number> = ref(0) // From 0 to 1;
const progress: Ref<number> = ref(0) // From 0 to 1; the progress input
const isLoading: Ref<boolean> = ref(false) // Loading state for the player
const currentDuration: Ref<number> = ref(0) // From 0 to totalDuration
const totalDuration: Ref<number> = ref(0) // From 0 to +Inf; duration of the current media, in seconds.

const startDuration: Ref<number> = ref(0)
const endDuration: Ref<number> = ref(0)
// temporary variable ^? v?
const currentProgramDuration: Ref<number> = ref(0)

const title: Ref<string> = ref('') // Podcast || Radio 's current title (name of current music)
const subtitle: Ref<string> = ref('') // Second line under the title, used for the artist name or the program name
const programName: Ref<string> = ref('') // Program name when listening podcast
const cover: Ref<Image> = ref({ src: '', alt: '' })

const radio: Ref<string> = ref() // Current SELECTED radio; must be a slug
const radioLabel: Ref<string> = ref('') // Current radio's full name -> can be merged within subtitle
const step: Ref<number> = ref(30) // Forward and backward button steps

const isProgram: Ref<boolean> = ref(true) // Tells us if this is currently a live program

const showRadioList: Ref<boolean> = ref(true) // Show the radio list or not (used for embedded version)

const canGoForward: Ref<boolean> = ref(true) // Computed value to disable the fordward button or not
const canGoBackward: Ref<boolean> = ref(true) // Computed value to disable he backward button or not
const errors: {
  message: string
}[] = []

const canGoToEnd: Ref<boolean> = ref(false) // Same for the go to the end button
const canGoToStart: Ref<boolean> = ref(false) // Ditto to go to the start of current media

const volume: Ref<number> = ref(0.5)

const isLiveDeferred = computed(() => Math.abs(progress.value - live.value) > 0.05)
const liveDeferred = computed(() => isProgram.value ? endDuration.value-currentDuration.value : endDuration.value-currentProgramDuration.value)


export const usePlayerState = () => {
  loading.value = 1

  return {
    isPlaying,
    isEnabled,
    progress,
    live,
    liveDeferred,
    loading,
    isLoading,
    startDuration,
    endDuration,
    currentDuration,
    currentProgramDuration,
    totalDuration,
    cover,
    title,
    subtitle,
    programName,
    isLive,
    isLiveDeferred,
    radio,
    radioLabel,
    isProgram,
    step,
    errors,
    canGoForward,
    canGoToStart,
    canGoToEnd,
    canGoBackward,
    showRadioList,
    volume
  }
}
