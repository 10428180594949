/**
 * An helper to stop/start the scroll on the page (<body>)
 * Used by the modales & pop-overs
 */

export const useScroll = () => {
  const root = document.querySelector('html')

  const start = () => root?.classList.remove('no-scroll')
  const stop = () => root?.classList.add('no-scroll')

  return { start, stop }
}
