/**
 * A swipe animation
 * Used for the player
 */

import gsap from 'gsap'
import Draggable from 'gsap/Draggable'

// threshold in pixels
// if above value, the swipe is successful
const THRESHOLD = 100

export const useSwipeAnimation = () => {
  if (process.client) {
    gsap.registerPlugin(Draggable)
  }

  const callback = ref((delta: number) => {})

  const initAnimation = (
    container: HTMLElement,
    handle: HTMLElement,
    orientation: 'x' | 'y',
    bounds: Object
  ) => {
    return Draggable.create(container, {
      type: orientation,
      handle,
      bounds,
      zIndexBoost: false,
      onDragEnd: function () {
        if (Math.abs(this[orientation]) > THRESHOLD) {
          callback.value &&
            callback.value(orientation === 'x' ? this.deltaX : this.deltaY)
        } else {
          gsap.to(container, {
            [orientation]: 0
          })
        }
      }
    })
  }
  return {
    initAnimation,
    callback
  }
}
