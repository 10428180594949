<template>
  <div
    v-show="visible"
    class="relative z-[100]">
    <div
      ref="background"
      class="fixed left-0 top-0 h-full w-full bg-black dt:bg-black/0"
      @click="close" />

    <div
      ref="content"
      :class="[
        'fixed bottom-0 left-0 pb-10 p-1 dt:pb-4 flex w-full flex-col justify-between overscroll-contain rounded-t-3xl bg-white text-black dt:absolute dt:left-[unset] dt:right-0  dt:w-[20rem] dt:rounded-xl dt:shadow-xl',
        {
          'dt:bottom-[unset] dt:top-[calc(100%+10px)]':
            desktopPosition === 'bottom',
          'dt:bottom-[calc(100%+60px)] dt:top-[unset]':
            desktopPosition === 'top'
        }
      ]">
      <div class="p-4 dt:hidden">
        <Icon
          name="close-fill"
          class="cursor-pointer text-3xl"
          @click="close" />
      </div>
      <div ref="scroller" class="max-h-[40vh] overflow-y-auto p-3">
        <slot :close="close" />
      </div>

      <div v-if="$slots.footer" class="px-3 pt-3">
        <slot
          name="footer"
          :close="close" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap'

import 'overlayscrollbars/overlayscrollbars.css'

import {
  OverlayScrollbars,
  SizeObserverPlugin
} from 'overlayscrollbars'

const props = withDefaults(
  defineProps<{
    visible?: boolean
    desktopPosition?: string
    scrollbar?: boolean
  }>(),
  {
    visible: false,
    desktopPosition: 'bottom',
    scrollbar: false
  }
)

const emit = defineEmits(['update:visible'])

const background = ref()
const content = ref()
const scroller = ref()

const open = () => {
  if (useScreen().isMobile.value) {
    animationMobile.play()
  } else {
    animationDesktop.play()
  }
}

const afterOpen = () => {}

const close = () => {
  if (useScreen().isMobile.value) {
    animationMobile.reverse()
  } else {
    animationDesktop.reverse()
  }
}

const afterClose = () => {
  emit('update:visible', false)
}

let animationMobile: gsap.core.Timeline
let animationDesktop: gsap.core.Timeline

const initAnimation = () => {
  animationMobile = gsap
    .timeline({
      onComplete: afterOpen,
      onReverseComplete: afterClose,
      paused: true
    })
    .fromTo(
      background.value,
      { opacity: 0 },
      {
        duration: 0.4,
        opacity: 0.8,
        ease: 'expo.out'
      }
    )
    .fromTo(
      content.value,
      {
        y: '100%'
      },
      {
        duration: 0.4,
        y: 0,
        ease: 'expo.out'
      },
      0
    )

  animationDesktop = gsap
    .timeline({
      onComplete: afterOpen,
      onReverseComplete: afterClose,
      paused: true
    })
    .fromTo(
      content.value,
      {
        y: 0
      },
      {
        duration: 0.001,
        y: 0
      },
      0
    )
}

watch(
  () => props.visible,
  () => {
    if (!props.visible) {
      close()
      return
    }

    open()
  }
)

watch(() => useScreen().isMobile.value, afterClose)

onMounted(() => {
  initAnimation()

  if (props.scrollbar) {
    initScrollbar()
  }
})

const initScrollbar = () => {
  OverlayScrollbars.plugin([SizeObserverPlugin])
  OverlayScrollbars(
    scroller.value,
    {
      scrollbars: {
        theme: 'os-theme-dark'
      },
      overflow: {
        x: 'hidden'
      }
    }
  )
}

</script>
