<template>
  <div
    :key="text"
    ref="container"
    class="relative whitespace-nowrap font-bold">
    <div
      ref="marquee"
      class="inline-flex">
      <span
        ref="content"
        :style="{ paddingRight: gap + 'px' }"
        >{{ text }}</span
      >
      <span v-if="isScrolling">{{ text }}</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import _ from 'lodash'
import gsap from 'gsap'

const props = withDefaults(
  defineProps<{
    text: string
    speed?: number
  }>(),
  {
    speed: 0.2
  }
)

const isScrolling = ref(true)

const gap = ref(20)

const container = ref()
const marquee = ref()
const content = ref()

const marqueeWidth = ref<number>()
const containerWidth = ref<number>()

let instance: gsap.core.Tween

onMounted(() => {
  marqueeWidth.value = content.value?.getBoundingClientRect().width - gap.value
  containerWidth.value = container.value?.getBoundingClientRect().width
  initAnimation()

  // reset animation on screen resize
  window.addEventListener('resize', initAnimationThrottled)
})

watch([() => props.text, marquee], () => {
  marqueeWidth.value = content.value?.getBoundingClientRect().width - gap.value
  containerWidth.value = container.value?.getBoundingClientRect().width

  initAnimation()
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', initAnimationThrottled)
})

const initAnimation = () => {
  instance?.kill()

  if (!containerWidth.value) return

  gsap.to(marquee.value, { x: 0 })

  if (marqueeWidth.value < containerWidth.value) {
    isScrolling.value = false
    return
  }

  isScrolling.value = true

  instance = gsap.fromTo(
    marquee.value,
    {
      x: 0
    },
    {
      duration: props.text.length * props.speed * 2,
      x: -(marqueeWidth.value + gap.value),
      delay: 2,
      repeatDelay: 0,
      repeat: -1,
      ease: 'none'
    }
  )
}

const initAnimationThrottled = _.throttle(initAnimation, 50)
</script>
