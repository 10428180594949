<template>
  <component
    :is="type === 'a' ? NuxtLink : 'button'"
    :aria-label="label"
    class="inline-flex items-center gap-1.5 rounded-2xl border border-neutral-700 px-3.5 py-2 text-white">
    <Icon
      v-if="leftIcon"
      :name="leftIcon"
      class="text-2xl" />
    {{ label }}
    <Icon
      v-if="rightIcon"
      :name="rightIcon"
      class="text-2xl" />
  </component>
</template>

<script lang="ts" setup>
const NuxtLink = resolveComponent('NuxtLink')

withDefaults(
  defineProps<{
    label?: string | null
    type?: 'button' | 'a'
    leftIcon?: string | null
    rightIcon?: string | null
  }>(),
  {
    type: 'button',
    leftIcon: null,
    rightIcon: null
  }
)
</script>
