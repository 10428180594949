import { DateTime } from 'luxon'

let lastFetch, fetchIntervalId: number, fetchScheduledProgramId: number

export default function usePlayerMeta() {
  const { $radios } = useNuxtApp()
  const config = useRuntimeConfig()

  const currentProgram: Ref<any> = ref(null)

  const { title, cover, radio, isProgram } = usePlayerState()

  const fetchScheduledPrograms = async () => {
    let currentRadio = $radios.find((r: any) => r.key == radio.value)
    if (!currentRadio?.id) return

    const current = (await $fetch(
      [config.public.apiBaseURL, 'api-corlab', 'schedules'].join('/'),
      {
        query: {
          from: DateTime.now().toFormat('yyyy-MM-dd'),
          to: DateTime.now().toFormat('yyyy-MM-dd'),
          radio: currentRadio.id
        }
      }
    )) as { data: Array<any> }

    const todaySchedules = current.data.find(
      (sch) => new Date(sch.date).toDateString() == new Date().toDateString()
    )

    currentProgram.value = todaySchedules.schedules.find(
      ({ time_start, time_end }: any) => {
        const start = parseInt(time_start.replaceAll(':', ''))
        const now = parseInt(
          `${new Date().getHours().toString().padStart(2, '0')}${new Date()
            .getMinutes()
            .toString()
            .padStart(2, '0')}${new Date()
            .getSeconds()
            .toString()
            .padStart(2, '0')}`
        )
        const end = parseInt(time_end.replaceAll(':', ''))

        return now > start && now < end
      }
    )
    if (!currentProgram.value) return
    isProgram.value = true

    cover.value.src = currentProgram.value.image || ''
    cover.value.alt = currentProgram.value.title
    title.value = currentProgram.value.title
    isProgram.value = true
  }

  const fetchNowPlayingTitle = async () => {
    if (currentProgram.value) return

    let response: any
    if (radio.value.toUpperCase() === 'BOA') {
      response = await $fetch(config.public.dataApiBaseUrl)

      cover.value = {
        src: `${config.public.boaCoverURL}?t=${new Date().getTime()}`,
        alt: `${response.artist} - ${response.title}`
      }
      title.value = `${response.artist} - ${response.title}`
      return
    }

    response = await $fetch(
      [
        config.public.azuracastApiBaseUrl,
        'api',
        'nowplaying',
        radio.value.toLowerCase()
      ].join('/')
    )

    if (title.value != response.now_playing.song.text) {
      cover.value = {
        src: `${response.now_playing.song.art}?t=${new Date().getTime()}`,
        alt: response.now_playing.song.text
      }
    }

    const currentRadio = $radios.find((r) => {
      return r.key == radio.value
    })

    const streamTitle = response.now_playing.song.text

    if (
      streamTitle.includes('Live Broadcast') ||
      streamTitle.includes('Stream Offline') ||
      streamTitle.includes('AzuraCast is Live!')
    ) {
      if (title.value == '') title.value = `${currentRadio.label}`

      return
    }

    title.value = response.now_playing.song.text
  }

  const registerNowPlayingInterval = async (newValue: boolean) => {
    clearInterval(fetchIntervalId)

    if (newValue) {
      fetchIntervalId = setInterval(fetchNowPlayingTitle, 5000)
      await fetchNowPlayingTitle() // Run once at first call
    }
  }

  const registerProgramScheduledInterval = async (newValue: boolean) => {
    clearInterval(fetchScheduledProgramId)

    currentProgram.value = null
    if (newValue) {
      fetchScheduledProgramId = setInterval(fetchScheduledPrograms, 15000)
      await fetchScheduledPrograms() // Run once at first call
    }
  }

  watch(radio, async (newValue, oldValue) => {
    if (newValue === oldValue) return

    // await registerProgramScheduledInterval(newValue)
    await registerNowPlayingInterval(newValue)
  })

  return {
    fetchNowPlayingTitle,
    fetchScheduledPrograms,
    currentProgram
  }
}
