import { DateTime } from 'luxon'
import usePlayerMeta from '@/plugins/player/meta'
import _ from 'lodash'

export default function playerBindings(playerElement: Ref<HTMLAudioElement>) {

  const { 
    isPlaying,
    isProgram,
    isLive,
    startDuration,
    endDuration,
    currentDuration,
    currentProgramDuration,
    totalDuration,
    progress,
    live,
    step,
    canGoForward,
    canGoToStart,
    canGoToEnd,
    canGoBackward,
    isEnabled,
    volume
    } = usePlayerState()

    const firstPlay = ref(true)

  const { currentProgram } = usePlayerMeta()

  const seek = (value: number) => {
    if (value > 1) return

    if (!currentProgram.value  && !isLive.value) {
      playerElement.value.currentTime = playerElement.value.duration * value
      return
    }

    const now = DateTime.now()
      .set({ year: 1970, month: 1, day: 1 })
      .plus({ hours: 1 })
      .toSeconds()

    const deltaCurrentTime = now - startDuration.value

    const deltaNotSeekable = Math.abs(
      playerElement.value.duration - deltaCurrentTime
    )

    const seekableRange = playerElement.value.duration - deltaCurrentTime
    const maxSeekable = playerElement.value.currentTime - seekableRange

    currentProgramDuration.value = now - playerElement.value.duration + playerElement.value.currentTime

    playerElement.value.currentTime = (value / live.value) * deltaCurrentTime + deltaNotSeekable
  }

  const forward = (value = 10) => {
    if (endDuration.value - playerElement.value.currentTime > value)
      playerElement.value.currentTime += value
  }

  const rewind = (value = 10) => {
    playerElement.value.currentTime -= value
  }

  function play() {
    if (firstPlay.value && isLive.value) {

      playerElement.value.currentTime = playerElement.value.duration - 15
      firstPlay.value = false
    }

    playerElement.value.play().catch(function(error) {
      console.warn(error)

      // if error, we are not playing
      isPlaying.value = false
    })
  }

  function pause() {
    try {
      playerElement.value.pause()
    } catch (err) {
      console.warn(err)
    }
  }

  const debounceToggle = _.debounce(
    () => {
      if (isPlaying.value === true) play()
      else pause()
    },
    1
  )

  watch(isPlaying, debounceToggle)


  watch(volume, () => {
    if (!playerElement.value) return
    playerElement.value.volume = volume.value;
  })

  const onTimeUpdate = () => {
    const now = DateTime.now()
      .set({ year: 1970, month: 1, day: 1 })
      .plus({ hours: 1 })
      .toSeconds()

    if (!currentProgram.value && !isLive.value) {

      endDuration.value = now

      // We're not listening to a program
      isProgram.value = false

      // The duration is the length of our media
      totalDuration.value = playerElement.value.duration || 0
      
      // Our duration is equal to the current time
      currentDuration.value = playerElement.value.currentTime || 0

      progress.value = currentDuration.value / totalDuration.value

      if (isLive.value) live.value = 1

      canGoForward.value = playerElement.value.duration - playerElement.value.currentTime > step.value
      canGoBackward.value = playerElement.value.currentTime > step.value
  
      canGoToStart.value = playerElement.value.currentTime > 5
      canGoToEnd.value = playerElement.value.duration - playerElement.value.currentTime > 5

      return
    }

    /**
     * This condition is only passed if there is currently a program
     * TODO: Optimization
     */

    // We write in the state that we are listening a program to change it's "layout"
    if (currentProgram.value) {
      isProgram.value = true

      // We build a new DateTime object based on today + provided end time
      endDuration.value = DateTime.fromISO(currentProgram.value.time_end)
        .set({ year: 1970, month: 1, day: 1 })
        .plus({ hours: 1 })
        .toSeconds()

      // We do the same using start time
      startDuration.value = DateTime.fromISO(currentProgram.value.time_start)
        .set({ year: 1970, month: 1, day: 1 })
        .minus({ hours: 1 })
        .toSeconds()
    } else {

      endDuration.value = DateTime.now()
        .set({ year: 1970, month: 1, day: 1 })
        .plus({ hours: 1 })
        .toSeconds()
  
      startDuration.value = DateTime.now()
        .set({ year: 1970, month: 1, day: 1 })
        .minus({ hours: 1 })
        .toSeconds()
      
      currentDuration.value = startDuration.value
    }


    // We define the delta between the begining and the end of our program
    const deltaProgramTime = endDuration.value - startDuration.value

    const deltaCurrentTime = now - startDuration.value

    const seekableRange = playerElement.value.duration - deltaCurrentTime
    const maxSeekable = playerElement.value.currentTime - seekableRange

    // This gives us the current progress based on the live
    progress.value = maxSeekable / deltaProgramTime

    // This defines the estimated live progress
    live.value = currentProgram.value ? (deltaCurrentTime / deltaProgramTime) : 1

    currentProgramDuration.value = now - playerElement.value.duration + playerElement.value.currentTime

    canGoForward.value = playerElement.value.duration - playerElement.value.currentTime > step.value
    canGoBackward.value = currentProgramDuration.value - startDuration.value > step.value

    canGoToStart.value = canGoBackward.value 
    canGoToEnd.value = canGoForward.value
  }

  return {
    seek,
    forward,
    rewind,
    onTimeUpdate
  }
}
