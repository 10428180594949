<template>


  <!-- did not work with NuxtLayout, had to create a custom PageLayout component-->
  <PageLayout v-if="$config.public.mode === 'website'">
    <div class="relative flex h-full flex-col justify-center overflow-hidden">
      <BackgroundPattern
        variant="top"
        class="dt:bg-pattern-right" />

      <Content class="pt-5 text-left dt:pt-14">
        <div class="relative">
          <div class="mx-auto max-w-3xl">
            <img
              src="/error/icon_error.svg"
              width="150"
              height="100"
              alt="Error"
              class="mb-10" />
            <h1 class="mb-8 text-left text-4xl font-bold text-neutral-400">
              {{ $t(`error.${code}.title`) }}
            </h1>

            <p
              v-html="$t(`error.${code}.description`)"
              class="mb-10 dt:max-w-[70%]" />

              <PrimaryButton
              :label="$t('error.go-home')"
              type="a"
              :to="localePath({ name: 'index' })"
              :dark="false" />
          </div>
        </div>
      </Content>
    </div>
  </PageLayout>

  <div>
    Erreur de configuration
  </div>

</template>

<script lang="ts" setup>
const localePath = useLocalePath()

const props = defineProps({
  error: Object
})

const code = computed(() => props.error?.statusCode || 500)
</script>
