import 'overlayscrollbars/overlayscrollbars.css'
import {
  OverlayScrollbars,
  ScrollbarsHidingPlugin,
  SizeObserverPlugin
} from 'overlayscrollbars'

export default defineNuxtPlugin(() => {
  OverlayScrollbars.plugin([ScrollbarsHidingPlugin, SizeObserverPlugin])

  OverlayScrollbars(
    {
      target: document.body,
      cancel: {
        nativeScrollbarsOverlaid: true,
        body: null
      }
    },
    {
      scrollbars: {
        theme: 'os-theme-light',
        autoHide: 'move'
      },
      overflow: {
        y: 'scroll'
      }
    }
  )
})
