import revive_payload_client_4sVQNw7RlN from "/workspace/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_jmwsqit4Rs from "/workspace/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import components_plugin_KR1HBZs4kY from "/workspace/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/workspace/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "/workspace/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_sLxaNGmlSL from "/workspace/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/workspace/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_client_i8AMfKeYnY from "/workspace/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/workspace/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _1_radio_list_lDIFNEQ0X2 from "/workspace/plugins/1.radio-list.ts";
import leaflet_client_7dEOJA4hbk from "/workspace/plugins/leaflet.client.ts";
import luxon_5MWj2Lijxd from "/workspace/plugins/luxon.ts";
import player_client_rMD6bat77c from "/workspace/plugins/player.client.ts";
import scrollbar_client_afVkuO58C3 from "/workspace/plugins/scrollbar.client.ts";
import tippy_client_WKoqvTKVZ4 from "/workspace/plugins/tippy.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  router_jmwsqit4Rs,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  plugin_client_i8AMfKeYnY,
  chunk_reload_client_UciE0i6zes,
  _1_radio_list_lDIFNEQ0X2,
  leaflet_client_7dEOJA4hbk,
  luxon_5MWj2Lijxd,
  player_client_rMD6bat77c,
  scrollbar_client_afVkuO58C3,
  tippy_client_WKoqvTKVZ4
]