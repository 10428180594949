import { default as _91id_9392WCa08gJcMeta } from "/workspace/embedded/pages/[radio]/episodes/[id].vue?macro=true";
import { default as indexylSPK4jPCIMeta } from "/workspace/embedded/pages/[radio]/episodes/index.vue?macro=true";
import { default as indexwoksRN1TKjMeta } from "/workspace/embedded/pages/[radio]/index.vue?macro=true";
import { default as playern01DpJp4lUMeta } from "/workspace/embedded/pages/[radio]/player.vue?macro=true";
import { default as _91id_93tCyNTNGcC4Meta } from "/workspace/embedded/pages/[radio]/programs/[id].vue?macro=true";
import { default as indexi1ZRkTFzGeMeta } from "/workspace/embedded/pages/index.vue?macro=true";
export default [
  {
    name: _91id_9392WCa08gJcMeta?.name ?? "radio-episodes-id",
    path: _91id_9392WCa08gJcMeta?.path ?? "/:radio()/episodes/:id()",
    meta: _91id_9392WCa08gJcMeta || {},
    alias: _91id_9392WCa08gJcMeta?.alias || [],
    redirect: _91id_9392WCa08gJcMeta?.redirect || undefined,
    component: () => import("/workspace/embedded/pages/[radio]/episodes/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_9392WCa08gJcMeta?.name ?? "radio-episodes-id___fr",
    path: _91id_9392WCa08gJcMeta?.path ?? "/fr/:radio()/episodes/:id()",
    meta: _91id_9392WCa08gJcMeta || {},
    alias: _91id_9392WCa08gJcMeta?.alias || [],
    redirect: _91id_9392WCa08gJcMeta?.redirect || undefined,
    component: () => import("/workspace/embedded/pages/[radio]/episodes/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_9392WCa08gJcMeta?.name ?? "radio-episodes-id___br",
    path: _91id_9392WCa08gJcMeta?.path ?? "/br/:radio()/episodes/:id()",
    meta: _91id_9392WCa08gJcMeta || {},
    alias: _91id_9392WCa08gJcMeta?.alias || [],
    redirect: _91id_9392WCa08gJcMeta?.redirect || undefined,
    component: () => import("/workspace/embedded/pages/[radio]/episodes/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_9392WCa08gJcMeta?.name ?? "radio-episodes-id___ga",
    path: _91id_9392WCa08gJcMeta?.path ?? "/ga/:radio()/episodes/:id()",
    meta: _91id_9392WCa08gJcMeta || {},
    alias: _91id_9392WCa08gJcMeta?.alias || [],
    redirect: _91id_9392WCa08gJcMeta?.redirect || undefined,
    component: () => import("/workspace/embedded/pages/[radio]/episodes/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_9392WCa08gJcMeta?.name ?? "radio-episodes-id___en",
    path: _91id_9392WCa08gJcMeta?.path ?? "/en/:radio()/episodes/:id()",
    meta: _91id_9392WCa08gJcMeta || {},
    alias: _91id_9392WCa08gJcMeta?.alias || [],
    redirect: _91id_9392WCa08gJcMeta?.redirect || undefined,
    component: () => import("/workspace/embedded/pages/[radio]/episodes/[id].vue").then(m => m.default || m)
  },
  {
    name: indexylSPK4jPCIMeta?.name ?? "radio-episodes",
    path: indexylSPK4jPCIMeta?.path ?? "/:radio()/episodes",
    meta: indexylSPK4jPCIMeta || {},
    alias: indexylSPK4jPCIMeta?.alias || [],
    redirect: indexylSPK4jPCIMeta?.redirect || undefined,
    component: () => import("/workspace/embedded/pages/[radio]/episodes/index.vue").then(m => m.default || m)
  },
  {
    name: indexylSPK4jPCIMeta?.name ?? "radio-episodes___fr",
    path: indexylSPK4jPCIMeta?.path ?? "/fr/:radio()/episodes",
    meta: indexylSPK4jPCIMeta || {},
    alias: indexylSPK4jPCIMeta?.alias || [],
    redirect: indexylSPK4jPCIMeta?.redirect || undefined,
    component: () => import("/workspace/embedded/pages/[radio]/episodes/index.vue").then(m => m.default || m)
  },
  {
    name: indexylSPK4jPCIMeta?.name ?? "radio-episodes___br",
    path: indexylSPK4jPCIMeta?.path ?? "/br/:radio()/episodes",
    meta: indexylSPK4jPCIMeta || {},
    alias: indexylSPK4jPCIMeta?.alias || [],
    redirect: indexylSPK4jPCIMeta?.redirect || undefined,
    component: () => import("/workspace/embedded/pages/[radio]/episodes/index.vue").then(m => m.default || m)
  },
  {
    name: indexylSPK4jPCIMeta?.name ?? "radio-episodes___ga",
    path: indexylSPK4jPCIMeta?.path ?? "/ga/:radio()/episodes",
    meta: indexylSPK4jPCIMeta || {},
    alias: indexylSPK4jPCIMeta?.alias || [],
    redirect: indexylSPK4jPCIMeta?.redirect || undefined,
    component: () => import("/workspace/embedded/pages/[radio]/episodes/index.vue").then(m => m.default || m)
  },
  {
    name: indexylSPK4jPCIMeta?.name ?? "radio-episodes___en",
    path: indexylSPK4jPCIMeta?.path ?? "/en/:radio()/episodes",
    meta: indexylSPK4jPCIMeta || {},
    alias: indexylSPK4jPCIMeta?.alias || [],
    redirect: indexylSPK4jPCIMeta?.redirect || undefined,
    component: () => import("/workspace/embedded/pages/[radio]/episodes/index.vue").then(m => m.default || m)
  },
  {
    name: indexwoksRN1TKjMeta?.name ?? "radio",
    path: indexwoksRN1TKjMeta?.path ?? "/:radio()",
    meta: indexwoksRN1TKjMeta || {},
    alias: indexwoksRN1TKjMeta?.alias || [],
    redirect: indexwoksRN1TKjMeta?.redirect || undefined,
    component: () => import("/workspace/embedded/pages/[radio]/index.vue").then(m => m.default || m)
  },
  {
    name: indexwoksRN1TKjMeta?.name ?? "radio___fr",
    path: indexwoksRN1TKjMeta?.path ?? "/fr/:radio()",
    meta: indexwoksRN1TKjMeta || {},
    alias: indexwoksRN1TKjMeta?.alias || [],
    redirect: indexwoksRN1TKjMeta?.redirect || undefined,
    component: () => import("/workspace/embedded/pages/[radio]/index.vue").then(m => m.default || m)
  },
  {
    name: indexwoksRN1TKjMeta?.name ?? "radio___br",
    path: indexwoksRN1TKjMeta?.path ?? "/br/:radio()",
    meta: indexwoksRN1TKjMeta || {},
    alias: indexwoksRN1TKjMeta?.alias || [],
    redirect: indexwoksRN1TKjMeta?.redirect || undefined,
    component: () => import("/workspace/embedded/pages/[radio]/index.vue").then(m => m.default || m)
  },
  {
    name: indexwoksRN1TKjMeta?.name ?? "radio___ga",
    path: indexwoksRN1TKjMeta?.path ?? "/ga/:radio()",
    meta: indexwoksRN1TKjMeta || {},
    alias: indexwoksRN1TKjMeta?.alias || [],
    redirect: indexwoksRN1TKjMeta?.redirect || undefined,
    component: () => import("/workspace/embedded/pages/[radio]/index.vue").then(m => m.default || m)
  },
  {
    name: indexwoksRN1TKjMeta?.name ?? "radio___en",
    path: indexwoksRN1TKjMeta?.path ?? "/en/:radio()",
    meta: indexwoksRN1TKjMeta || {},
    alias: indexwoksRN1TKjMeta?.alias || [],
    redirect: indexwoksRN1TKjMeta?.redirect || undefined,
    component: () => import("/workspace/embedded/pages/[radio]/index.vue").then(m => m.default || m)
  },
  {
    name: playern01DpJp4lUMeta?.name ?? "radio-player",
    path: playern01DpJp4lUMeta?.path ?? "/:radio()/player",
    meta: playern01DpJp4lUMeta || {},
    alias: playern01DpJp4lUMeta?.alias || [],
    redirect: playern01DpJp4lUMeta?.redirect || undefined,
    component: () => import("/workspace/embedded/pages/[radio]/player.vue").then(m => m.default || m)
  },
  {
    name: playern01DpJp4lUMeta?.name ?? "radio-player___fr",
    path: playern01DpJp4lUMeta?.path ?? "/fr/:radio()/player",
    meta: playern01DpJp4lUMeta || {},
    alias: playern01DpJp4lUMeta?.alias || [],
    redirect: playern01DpJp4lUMeta?.redirect || undefined,
    component: () => import("/workspace/embedded/pages/[radio]/player.vue").then(m => m.default || m)
  },
  {
    name: playern01DpJp4lUMeta?.name ?? "radio-player___br",
    path: playern01DpJp4lUMeta?.path ?? "/br/:radio()/player",
    meta: playern01DpJp4lUMeta || {},
    alias: playern01DpJp4lUMeta?.alias || [],
    redirect: playern01DpJp4lUMeta?.redirect || undefined,
    component: () => import("/workspace/embedded/pages/[radio]/player.vue").then(m => m.default || m)
  },
  {
    name: playern01DpJp4lUMeta?.name ?? "radio-player___ga",
    path: playern01DpJp4lUMeta?.path ?? "/ga/:radio()/player",
    meta: playern01DpJp4lUMeta || {},
    alias: playern01DpJp4lUMeta?.alias || [],
    redirect: playern01DpJp4lUMeta?.redirect || undefined,
    component: () => import("/workspace/embedded/pages/[radio]/player.vue").then(m => m.default || m)
  },
  {
    name: playern01DpJp4lUMeta?.name ?? "radio-player___en",
    path: playern01DpJp4lUMeta?.path ?? "/en/:radio()/player",
    meta: playern01DpJp4lUMeta || {},
    alias: playern01DpJp4lUMeta?.alias || [],
    redirect: playern01DpJp4lUMeta?.redirect || undefined,
    component: () => import("/workspace/embedded/pages/[radio]/player.vue").then(m => m.default || m)
  },
  {
    name: _91id_93tCyNTNGcC4Meta?.name ?? "radio-programs-id",
    path: _91id_93tCyNTNGcC4Meta?.path ?? "/:radio()/programs/:id()",
    meta: _91id_93tCyNTNGcC4Meta || {},
    alias: _91id_93tCyNTNGcC4Meta?.alias || [],
    redirect: _91id_93tCyNTNGcC4Meta?.redirect || undefined,
    component: () => import("/workspace/embedded/pages/[radio]/programs/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93tCyNTNGcC4Meta?.name ?? "radio-programs-id___fr",
    path: _91id_93tCyNTNGcC4Meta?.path ?? "/fr/:radio()/programs/:id()",
    meta: _91id_93tCyNTNGcC4Meta || {},
    alias: _91id_93tCyNTNGcC4Meta?.alias || [],
    redirect: _91id_93tCyNTNGcC4Meta?.redirect || undefined,
    component: () => import("/workspace/embedded/pages/[radio]/programs/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93tCyNTNGcC4Meta?.name ?? "radio-programs-id___br",
    path: _91id_93tCyNTNGcC4Meta?.path ?? "/br/:radio()/programs/:id()",
    meta: _91id_93tCyNTNGcC4Meta || {},
    alias: _91id_93tCyNTNGcC4Meta?.alias || [],
    redirect: _91id_93tCyNTNGcC4Meta?.redirect || undefined,
    component: () => import("/workspace/embedded/pages/[radio]/programs/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93tCyNTNGcC4Meta?.name ?? "radio-programs-id___ga",
    path: _91id_93tCyNTNGcC4Meta?.path ?? "/ga/:radio()/programs/:id()",
    meta: _91id_93tCyNTNGcC4Meta || {},
    alias: _91id_93tCyNTNGcC4Meta?.alias || [],
    redirect: _91id_93tCyNTNGcC4Meta?.redirect || undefined,
    component: () => import("/workspace/embedded/pages/[radio]/programs/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93tCyNTNGcC4Meta?.name ?? "radio-programs-id___en",
    path: _91id_93tCyNTNGcC4Meta?.path ?? "/en/:radio()/programs/:id()",
    meta: _91id_93tCyNTNGcC4Meta || {},
    alias: _91id_93tCyNTNGcC4Meta?.alias || [],
    redirect: _91id_93tCyNTNGcC4Meta?.redirect || undefined,
    component: () => import("/workspace/embedded/pages/[radio]/programs/[id].vue").then(m => m.default || m)
  },
  {
    name: indexi1ZRkTFzGeMeta?.name ?? "index",
    path: indexi1ZRkTFzGeMeta?.path ?? "/",
    meta: indexi1ZRkTFzGeMeta || {},
    alias: indexi1ZRkTFzGeMeta?.alias || [],
    redirect: indexi1ZRkTFzGeMeta?.redirect || undefined,
    component: () => import("/workspace/embedded/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexi1ZRkTFzGeMeta?.name ?? "index___fr",
    path: indexi1ZRkTFzGeMeta?.path ?? "/fr",
    meta: indexi1ZRkTFzGeMeta || {},
    alias: indexi1ZRkTFzGeMeta?.alias || [],
    redirect: indexi1ZRkTFzGeMeta?.redirect || undefined,
    component: () => import("/workspace/embedded/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexi1ZRkTFzGeMeta?.name ?? "index___br",
    path: indexi1ZRkTFzGeMeta?.path ?? "/br",
    meta: indexi1ZRkTFzGeMeta || {},
    alias: indexi1ZRkTFzGeMeta?.alias || [],
    redirect: indexi1ZRkTFzGeMeta?.redirect || undefined,
    component: () => import("/workspace/embedded/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexi1ZRkTFzGeMeta?.name ?? "index___ga",
    path: indexi1ZRkTFzGeMeta?.path ?? "/ga",
    meta: indexi1ZRkTFzGeMeta || {},
    alias: indexi1ZRkTFzGeMeta?.alias || [],
    redirect: indexi1ZRkTFzGeMeta?.redirect || undefined,
    component: () => import("/workspace/embedded/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexi1ZRkTFzGeMeta?.name ?? "index___en",
    path: indexi1ZRkTFzGeMeta?.path ?? "/en",
    meta: indexi1ZRkTFzGeMeta || {},
    alias: indexi1ZRkTFzGeMeta?.alias || [],
    redirect: indexi1ZRkTFzGeMeta?.redirect || undefined,
    component: () => import("/workspace/embedded/pages/index.vue").then(m => m.default || m)
  }
]