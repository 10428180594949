<template>
  <div
    ref="container"
    class="relative h-1 w-full">
    <div
      class="absolute left-0 top-0 z-[1] h-5 w-full -translate-y-1/2 transform"
      @click="onClick"></div>

    <!-- grey loading bar -->
    <div
      ref="loadingBar"
      class="absolute left-0 top-0 h-full rounded-xl bg-white/30"></div>

    <!-- red live bar with cursor -->
    <div
      v-if="isLive"
      ref="liveBar"
      class="absolute left-0 top-0 h-full rounded-xl bg-red">
      <!-- live badge, for full screen player -->
      <div
        v-if="isProgram && liveBadge"
        class="absolute bottom-0 right-0 h-[15px] w-[2px] translate-x-1/2 transform bg-red"></div>
      <div
        v-if="isProgram && liveBadge"
        @click="$player.seek(live - 0.001)"
        class="absolute bottom-[calc(100%+8px)] right-0 z-[5] translate-x-1/2 transform cursor-pointer select-none rounded-md bg-red px-2 py-1 text-xs font-bold text-white">
        {{ $t('PlayerProgressBar.live-badge') }}
      </div>

      <!-- live red dot, for small player -->
      <div
        v-if="isProgram && !liveBadge"
        title="Retour au direct"
        @click="$player.seek(live - 0.001)"
        class="has-live-hover absolute right-0 top-1/2 z-[1] h-[13px] w-[13px] -translate-y-1/2 translate-x-1/2 transform rounded-full bg-red" />
    </div>

    <!-- progress bar, in white for an episode -->
    <div
      ref="progressBar"
      class="absolute left-0 top-0 h-full w-1 rounded-xl bg-white"></div>

    <!-- a bigger transparent cursor around the white dot for better UX -->
    <div
      ref="cursor"
      class="absolute -left-[20px] top-1/2 z-[2] h-10 w-10 -translate-y-1/2 transform rounded-full">
      <div
        class="absolute left-[calc(50%-4px)] top-1/2 h-2 w-2 -translate-y-1/2 transform rounded-full bg-white">
        <!-- if it's a live program and we move the cursor from live, show current duration -->
        <div
          v-if="
            (isProgram || isLive) &&
            isLiveDeferred &&
            showCurrentDuration
          "
          class="absolute bottom-[4px] right-0 translate-x-1/2 transform rounded-md px-2 py-1 text-white">
          <PlayerTime
            :seconds="currentProgramDuration"
            :show-seconds="false"
            text-style="text-xs"
            class="text-center" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import _ from 'lodash'
import gsap from 'gsap'

import Draggable from 'gsap/Draggable'

withDefaults(
  defineProps<{
    liveBadge?: boolean
    showCurrentDuration?: boolean
  }>(),
  {
    liveBadge: false,
    showCurrentDuration: true
  }
)

// transition duration for animations, in seconds
const DURATION = 0

const container = ref()
const cursor = ref()

const progressBar = ref()
const liveBar = ref()
const loadingBar = ref()

const { progress, live, loading, isProgram, isLive, isLiveDeferred, currentProgramDuration } =
  usePlayerState()

const { $player } = useNuxtApp()

const emit = defineEmits(['update:progress'])

let instance: Draggable[]
const isDragged = ref(false)
const initDraggable = () => {
  if (!cursor.value) return

  gsap.registerPlugin(Draggable)

  instance = Draggable.create(cursor.value, {
    type: 'x',
    bounds: {
      minX: 0,
      maxX: container.value.getBoundingClientRect()?.width
    },
    zIndexBoost: false,
    onDragStart: function () {
      isDragged.value = true
    },
    onDragEnd: function () {
      isDragged.value = false
    },
    onDrag: function () {
      setBarWidth(progressBar.value, this.x)

      onUserProgressUpdate()
    }
  })
}

const init = () => {
  instance?.[0]?.kill()

  initDraggable()

  setProgress()
  setLive()
  setLoading()
}

const initThrottled = _.throttle(init, 50)

onMounted(() => {
  init()

  // reset animation on screen resize
  window.addEventListener('resize', initThrottled)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', initThrottled)
})

const onClick = (event) => {
  const width = container.value.getBoundingClientRect()?.width
  const targetProgress = event.offsetX
  const liveProgress = live.value * width

  setCursorPosition(Math.min(targetProgress, liveProgress))
  setBarWidth(progressBar.value, Math.min(targetProgress, liveProgress))
  onUserProgressUpdate()
}

const setBarWidth = (element: HTMLElement, x: number) => {
  if (!element) return

  gsap.to(element, {
    duration: DURATION,
    width: x
  })
}

const setCursorPosition = (x: number) => {
  if (!cursor.value) return
  gsap.to(cursor.value, { duration: DURATION, x })
}

const onUserProgressUpdate = () => {
  const _progress =
    (gsap.getProperty(cursor.value, 'x') as number) /
    container.value.getBoundingClientRect()?.width

  $player.seek(_progress)
  emit('update:progress', _progress)
}

const setProgress = () => {
  const width = container.value.getBoundingClientRect()?.width
  setBarWidth(progressBar.value, progress.value * width)
  setCursorPosition(progress.value * width)
}

const setLive = () => {
  const width = container.value.getBoundingClientRect()?.width

  setBarWidth(liveBar.value, live.value * width)

  if (!isDragged.value)
    Draggable.get(cursor.value).applyBounds({
      minX: 0,
      maxX: live.value * width
    })
}

const setLoading = () => {
  const width = container.value.getBoundingClientRect()?.width

  setBarWidth(loadingBar.value, loading.value * width)
}

watch(progress, setProgress)
watch(live, setLive)
watch(loading, setLoading)
</script>
