<template>
  <div
    class="grid gap-2"
    :style="{
      'grid-template-columns': `repeat(${items.length}, minmax(0, 1fr))`
    }">
    <PrimaryButton
      v-for="(item, index) in items"
      :key="index"
      :label="item.key"
      :dark="locale !== item.key"
      :animated="locale !== item.key"
      class="aspect-square w-full rounded-full uppercase"
      extra-class="!p-2"
      @click="setLocale(item.key)" />
  </div>
</template>

<script setup lang="ts">
import { LocaleObject } from 'vue-i18n-routing'

const { locales, setLocale, t, locale } = useI18n()

const items = computed(() =>
  locales.value.map((proxy) => {
    // need to convert proxy object to LocalObject
    const option = toRaw(proxy) as LocaleObject

    return {
      key: option.code,
      label: t(`LocaleDropdown.${option.code}`)
    }
  })
)
</script>
