/**
 * Show/hide the modal and edit its content
 * The <Modal /> component is in the layout
 */

const visible = ref(false)
const content = ref('')

const show = () => {
  visible.value = true
}

const hide = () => {
  visible.value = false
}

export const useModal = () => {
  return {
    content,
    visible,
    show,
    hide
  }
}
