<template>
  <div class="player mx-auto flex h-full flex-col items-center justify-center gap-6 h-sm:max-w-md">
    <div class="relative z-20 hidden h-xs:block">
      <RadioFilter v-if="showRadioList" @select="selectRadio" />
    </div>

    <div class="flex w-full justify-around gap-5 h-sm:flex-col">
      <div class="mx-auto w-[80vh] max-w-[200px] h-xs:w-[200px] h-xs:max-w-none h-sm:w-[40vh] h-lg:w-full">
        <PlayerCover :full-size="true" />
      </div>

      <div class="flex flex-grow flex-col items-center justify-center gap-2 h-sm:flex-grow-0 h-md:gap-6">
        <div class="w-full">
          <div class="flex items-center gap-2">
            <PlayerLiveDot />
            <HorizontalScrollingText v-if="subtitle" :text="`${$t('Player.listening')} ${subtitle}`"
              class="w-full max-w-full font-bold text-xs" />
          </div>

          <PlayerMainTitle class="w-fit max-w-full" />

          <PlayerSecondTitle class="w-full max-w-full font-bold text-xs" />
        </div>

        <PlayerTimeline class="relative z-10 w-full" :live-badge="true" />

        <PlayerControls class="relative z-10 scale-75 transform h-xs:scale-100" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const { radio, showRadioList, subtitle } = usePlayerState()

const selectRadio = (r: string) => {
  radio.value = r.key as string
}
</script>
