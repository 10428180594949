<template>
  <div
    class="flex w-full items-center gap-2"
    @mouseover="opacity = 1"
    @mouseleave="opacity = 0">
    <!-- icons, show mute if volume is 0 -->
    <Icon
      v-if="volume === 0"
      name="volume-mute-line"
      class="relative z-10 cursor-pointer text-xl"
      @click="unmute" />
    <Icon
      v-if="volume > 0"
      name="volume-up-line"
      class="relative z-10 cursor-pointer text-xl"
      @click="mute" />

    <!-- control bar -->
    <VolumeBar
      class="transition duration-200 ease-in-out"
      :style="{ opacity }" />
  </div>
</template>

<script lang="ts" setup>
const opacity = ref(0)

const { volume } = usePlayerState()

const lastVolume = ref(volume.value)
const mute = () => {
  lastVolume.value = volume.value

  volume.value = 0
}
const unmute = () => {
  // for better UX, if volume is too low, set it to 0.5
  if (lastVolume.value > 0.1) {
    volume.value = lastVolume.value
  } else {
    volume.value = 0.5
  }

}
</script>
