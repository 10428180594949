<template>
  <div class="flex flex-col items-center border-t border-neutral-800 bg-neutral-900 px-5 py-8 text-white">
    <div class="grid-col-1 grid w-full max-w-3xl dt:grid-cols-3 dt:gap-20">
      <!-- financers logos -->
      <div class="flex flex-col items-start">
        <div class="mb-2.5">{{ $t('Footer.cofinanced-by') }}</div>
        <div class="mb-7 flex dt:flex-col gap-2 items-start">
          <div v-for="(logo, index) in financers" :key="index"
            class="h-24 rounded-xl bg-neutral-900 p-1.5 dt:h-24 first:h-32">
            <NuxtImg format="webp" sizes="130px sm:170px md:170px" :src="logo" alt="Logo BOA" width="130" height="100"
              fit="contain" class="h-full w-full object-contain" />
          </div>
        </div>
      </div>

      <!-- links -->
      <ul class="mb-7 flex flex-col items-start gap-3 text-base">
        <li v-for="(link, index) in links">
          <NuxtLink :to="link.path">
            {{ link.title }}
          </NuxtLink>
        </li>
        <li>
          <LocaleSwitcher />
        </li>
      </ul>

      <!-- address -->
      <div class="flex flex-col items-center">
        <address class="mb-7 text-center not-italic">
          <div class="font-bold">{{ $t('Footer.address.radio-boa') }}</div>
          <div>{{ $t('Footer.address.street') }}</div>
          <div>{{ $t('Footer.address.city') }}</div>
        </address>

        <!-- social networks -->
        <SocialNetworkList facebook="https://www.facebook.com/radio.boa.bzh" instagram="https://www.instagram.com/radio.boa/" twitter="https://twitter.com/radioboa_bzh" linkedin="https://www.linkedin.com/company/radio-b-o-a/" class="mb-7" />
      </div>
    </div>

    <!-- footnotes -->
    <div class="flex justify-center gap-2 text-xs text-neutral-400">
      <NuxtLink :to="localePath({ name: 'legal' })">
        {{ $t('Footer.legal-terms') }}
      </NuxtLink>
      -
      <p> {{ $t('Footer.cookie') }}</p>
      -
      <a href="https://lumy.bzh" target="_blank">
        {{ $t('Footer.powered-by-lumy') }} © {{ new Date().getFullYear() }} Radio BOA
      </a>
    </div>
  </div>
</template>

<script lang="ts" setup>
const { t } = useI18n()
const localePath = useLocalePath()

const financers = [
  '/footer/union_europeenne_logo.png',
  '/footer/region_bretagne_logo.png'
]

const links = [
  {
    title: t('Footer.radio-boa'),
    path: localePath({ name: 'about' })
  },
  {
    title: t('Footer.radio-partners'),
    path: localePath({ name: 'partner-radios' })
  },
  {
    title: t('Footer.sponsors'),
    path: localePath({ name: 'sponsors' })
  },
 /*  {
    title: t('Footer.subscribe-newsletter'),
    path: localePath({ name: 'index', hash: '#newsletter' })
  }, */
  {
    title: t('Footer.contact'),
    path: localePath({ name: 'contact' })
  }
]
</script>
