<template>
  <div class="flex items-center justify-center gap-2">
    <!-- left time label -->

    <div
      class="direction-rtl w-[40px] basis-[40px] whitespace-nowrap text-right">
      <div
        v-if="isLive && progress > 0.99"
        class="text-sm uppercase">
        {{ $t('PlayerProgressBar.live') }}
      </div>
      <PlayerTime
        v-else
        :seconds="!isProgram ? currentDuration : startDuration"
        :show-seconds="!isLive"
        :show-duration="isLive" />
    </div>
    <!-- progress -->
    <div class="relative flex-grow px-1">
      <PlayerProgressBar :live-badge="liveBadge" />
    </div>

    <!-- right time label -->
    <PlayerTime
      :seconds="isLive ? endDuration : totalDuration"
      :show-seconds="!isLive"
      :show-duration="isLive" />
  </div>
</template>

<script lang="ts" setup>
const {
  progress,
  currentDuration,
  totalDuration,
  startDuration,
  endDuration,
  isLive,
  isProgram
} = usePlayerState()

withDefaults(
  defineProps<{
    liveBadge?: boolean
  }>(),
  {
    liveBadge: false
  }
)
</script>
