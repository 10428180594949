/**
 * Tippy is used to create tooltips
 * Just set on a element the attribute : data-tippy-content="Hello world" 
 * and the tooltip will appear
 */

import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css'; 
import 'tippy.js/themes/light.css';

export default defineNuxtPlugin(() => {
  tippy('[data-tippy-content]', {
    theme: 'light'
  })
})
