import usePlayerBindings from '@/plugins/player/bindings'
import usePlayerActions from '@/plugins/player/actions'
import usePlayerMeta from '@/plugins/player/meta'
import { translateItem } from '@/composables/use-translations'

let hls: Hls
let playerElement: Ref<HTMLAudioElement> = ref()

export default defineNuxtPlugin(async (ctx) => {
  const config = useRuntimeConfig()
  const { $i18n, $radios } = ctx

  const {
    radio,
    title,
    cover,
    isLoading,
    isProgram,
    isLive,
    loading,
    live,
    programName,
    isPlaying,
    radioLabel
  } = usePlayerState()
  const { currentProgram } = usePlayerMeta()

  const { createAudioElement } = usePlayerActions(playerElement)

  const loadEpisode = async (episodeId: number, autoplay = true) => {
    if (isLoading.value == true) return

    isLoading.value = true
    loading.value = 1
    isLive.value = false
    isProgram.value = false
    currentProgram.value = false
    radio.value = false
    live.value = 1

    const response = (await $fetch(
      [config.public.apiBaseURL, 'items', 'episode', episodeId.toString()].join(
        '/'
      ),
      {
        params: {
          fields: [
            'id',
            'podcast',
            'translations.*',
            'image',
            'program',
            'program.translations.*, program.radio.name'
          ]
        }
      }
    )) as { data: Program }

    const responseData = response?.data as Program

    const episodeMeta = translateItem(responseData)
    createAudioElement(
      [config.public.apiBaseURL, 'assets', responseData.podcast].join('/'),
      true
    )

    title.value = episodeMeta?.title
    radioLabel.value = episodeMeta?.program?.radio?.name

    programName.value =
      translateItem(responseData.program).title ?? "pas d'émission"

    cover.value.alt = title.value
    // add title and name of program
    cover.value.src = useApiAsset(responseData.image ?? null, {
      placeholder: 'square'
    })

    isLoading.value = false
  }

  const loadAudiofile = async (
    url: string,
    audioTitle: string,
    audioCover: string
  ) => {
    if (isLoading.value == true) return

    isLoading.value = true
    isLive.value = false
    isProgram.value = false
    currentProgram.value = null
    radio.value = false

    createAudioElement(url, true)

    title.value = audioTitle
    cover.value = {
      alt: audioTitle || '',
      src: audioCover || ''
    }
    isLoading.value = false
  }

  const hasPlayerLoaded = computed(() => playerElement.value !== undefined)

  return {
    provide: {
      player: {
        ...usePlayerBindings(playerElement),
        loadEpisode,
        loadAudiofile,
        hasPlayerLoaded,
        loadRadio: (radioId: number) => {
          const currentRadio = $radios.find((r: any) => r.id == radioId)
          radio.value = currentRadio.key || null
        }
      }
    }
  }
})
