<template>
  <div class="relative">
    <PrimaryButton :label="isLive ? $t('RadioFilter.title') : $t('RadioFilter.listen')" :dark="false"
      left-icon="broadcast-fill" right-icon="arrow-drop-right-line" :animated="false" class="border-0"
      @click="panelVisible = true" />

    <BottomPanel v-model:visible="panelVisible" desktop-position="top" :scrollbar="true">
      <template #default>
        <ul class="flex flex-col gap-2">
          <li 
            v-for="(radio, index) in radios" 
            :key="radio.key + index"
            @click="selectRadio(radio)"
            class="flex cursor-pointer items-center gap-5"
            >
            <NuxtImg
              :id="radio.image.src"
              format="webp"
              sizes="sm:50px"
              :src="radio.image.src"
              fit="contain"
              :alt="radio.image.alt" provider="directus" width="50" height="50"
              class="aspect-square rounded-md object-contain"
            />
            {{ radio.label }}
          </li>
        </ul>
      </template>
    </BottomPanel>
  </div>
</template>

<script lang="ts" setup>
const { isLive, radioLabel } = usePlayerState()

const { $radios } = useNuxtApp()

// filter on not null key (azuracast_station_id)
const radios = computed(() => $radios?.filter(r => r.key))

const panelVisible = ref(false)
const emit = defineEmits(['select'])

const selectRadio = (radio: any) => {
  panelVisible.value = false
  emit('select', radio)
}
</script>
