<template>
  <div :class="['w-[40px] basis-[40px]', 'select-none', textStyle]">
    <span class="direction-ltr inline-block">{{ formattedTime }}</span>
  </div>
</template>

<script lang="ts" setup>
import _ from 'lodash'

import { Duration } from 'luxon'

const props = withDefaults(
  defineProps<{
    seconds?: number
    showDuration?: boolean
    showSeconds?: boolean
    textStyle?: string
  }>(),
  {
    seconds: 0,
    showDuration: false,
    showSeconds: false,
    textStyle: 'text-sm'
  }
)

const { seconds, showDuration, showSeconds } = toRefs(props)

const durationOrTwoHours: Ref<number> = computed(() =>
  _.min([seconds.value, 2 * 3600])
)

const formattedTime = computed(() =>
  showDuration.value
    ? Duration.fromMillis(seconds.value * 1000 || 0).toFormat("h'h'mm")
    : Duration.fromMillis(seconds.value * 1000 || 0).toFormat(
        (seconds.value > 3600 ? 'hh:mm' : 'mm') +
          (showSeconds.value ? ':ss' : '')
      )
)
</script>
