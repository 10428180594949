<template>
  <div class="flex flex-col items-center px-4 py-10">
    <NuxtLink
      :to="localePath({ name: 'index' })"
      class="mb-5 block">
      <NuxtImg
        format="webp"
        sizes="sm:100px md:250px"
        src="/logo_white_vertical.svg"
        width="120"
        height="158"
        alt="BOA logo" />
    </NuxtLink>

    <SideMenu class="mb-10 bg-neutral-900" />

    <WhatSongModule class="mb-5 w-full" />

    <div class="flex w-full justify-center dt:justify-start">
      <LocaleSwitcher />
    </div>
  </div>
</template>

<script lang="ts" setup>
const localePath = useLocalePath()
</script>
