<template>
  <component
    :is="type === 'a' ? NuxtLink : 'button'"
    :class="[
      'flex aspect-square w-12 items-center justify-center rounded-xl',
      {
        'bg-neutral-800 text-white': dark,
        'bg-white text-neutral-800': !dark,
        'border border-neutral-700 !bg-white/0': outline
      }
    ]">
    <Icon
      :name="icon"
      :class="['text-2xl', iconExtraClass]" />
  </component>
</template>

<script lang="ts" setup>
const NuxtLink = resolveComponent('NuxtLink')

withDefaults(
  defineProps<{
    icon: string
    type?: 'button' | 'a'
    dark?: boolean
    outline?: boolean
    iconExtraClass?: string
  }>(),
  {
    type: 'button',
    dark: true,
    outline: false
  }
)
</script>
