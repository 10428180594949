<template>
  <div
    v-show="visible"
    class="absolute left-0 top-0 h-full w-full">
    <!-- background -->
    <div
      ref="background"
      class="absolute z-40 h-full w-full bg-neutral-800/80"
      @click="close"></div>

    <!-- content -->
    <div
      ref="content"
      class="fixed bottom-0 right-0 z-[100] flex h-[calc(100vh-100px)] w-full flex-col gap-3 rounded-t-3xl bg-white p-4 text-black dt:bottom-[unset] dt:top-0 dt:z-40 dt:w-[45vw] dt:rounded-none">
      <!-- close button -->
      <div class="flex dt:justify-end">
        <Icon
          name="close-fill"
          class="cursor-pointer text-2xl"
          @click="close" />
      </div>
      <div
        class="flex-grow overflow-scroll"
        v-html="modalContent" />

      <!-- bottom close button -->
      <div>
        <PrimaryButton
          :label="$t('Modal.close')"
          class="w-full"
          @click="close" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import gsap from 'gsap'

const { content: modalContent, visible, hide: hideModal } = useModal()

const background = ref()
const content = ref()

const open = () => {
  if (useScreen().isMobile.value) {
    animationMobile.timeScale(1).play()
  } else {
    animationDesktop.timeScale(1).play()
  }
}

const afterOpen = () => {
  useScroll().stop()
}

const close = () => {
  if (useScreen().isMobile.value) {
    animationMobile.timeScale(2).reverse()
  } else {
    animationDesktop.timeScale(2).reverse()
  }
}

const afterClose = () => {
  hideModal()
  useScroll().start()
}

let animationMobile: gsap.core.Timeline
let animationDesktop: gsap.core.Timeline

const initAnimation = () => {
  animationMobile = gsap
    .timeline({
      onComplete: afterOpen,
      onReverseComplete: afterClose,
      paused: true
    })
    .fromTo(
      background.value,
      { opacity: 0 },
      {
        duration: 0.4,
        opacity: 1,
        ease: 'expo.out'
      }
    )
    .fromTo(
      content.value,
      {
        x: 0,
        y: '100%'
      },
      {
        duration: 0.6,
        x: 0,
        y: 0,
        ease: 'expo.out'
      },
      0
    )

  animationDesktop = gsap
    .timeline({
      onComplete: afterOpen,
      onReverseComplete: afterClose,
      paused: true
    })
    .fromTo(
      background.value,
      { opacity: 0 },
      {
        duration: 0.4,
        opacity: 1,
        ease: 'expo.out'
      }
    )
    .fromTo(
      content.value,
      {
        x: '100%',
        y: 0
      },
      {
        duration: 0.6,
        x: 0,
        y: 0,
        ease: 'expo.out'
      },
      0
    )
}

watch(
  () => visible.value,
  () => {
    if (!visible.value) {
      close()
      return
    }

    open()
  }
)

watch(() => useScreen().isMobile.value, afterClose)

onMounted(() => {
  initAnimation()
})
</script>
