<template>
  <div class="flex gap-x-4">
    <NuxtLink
      v-for="(network, index) in networks.filter((n) => n.path)"
      :key="index"
      :to="network.path"
      target="_blank"
      :aria-label="network.label"
      class="flex aspect-square min-h-0 items-center justify-center rounded-xl border border-neutral-800 p-3">
      <Icon :name="network.icon" />
    </NuxtLink>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  facebook?: string
  instagram?: string
  twitter?: string
  linkedin?: string
}>()

const networks = computed(() => [
  {
    label: 'facebook',
    icon: 'facebook-fill',
    path: props.facebook
  },
  {
    label: 'instagram',
    icon: 'instagram-fill',
    path: props.instagram
  },
  {
    label: 'twitter',
    icon: 'twitter-x-fill',
    path: props.twitter
  },
  {
    label: 'linkedin',
    icon: 'linkedin-fill',
    path: props.linkedin
  },
])
</script>
