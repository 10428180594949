<template>
  <NuxtLink
    :to="localePath({ name: 'what-song' })"
    class="block">
    <div class="flex gap-4 rounded-[50px] items-center bg-white p-1.5">
      <div
        class="flex aspect-square h-full min-h-0 w-auto items-center justify-center rounded-full bg-neutral-800 p-3.5 text-white">
        <Icon name="play-list-fill" />
      </div>
      <div class="flex flex-col justify-center pr-2 leading-tight dt:pr-0">
        <div class="title font-bold text-neutral-800">
          {{ $t('WhatSongModule.what-was-that-song') }}
        </div>
        <div class="subtitle text-neutral-700">
          {{ $t('WhatSongModule.find-out-past-songs') }}
        </div>
      </div>
    </div>
  </NuxtLink>
</template>

<script setup lang="ts">
const localePath = useLocalePath()
</script>

<style scoped>
@media (max-width: 1600px) {
  .module > div {
    @apply gap-2;
    @apply bg-red;
  }
  .title {
    @apply pr-10;
  }
  .subtitle {
    @apply hidden;
  }
}

@media (max-width: 1200px) {
  .title {
    @apply pr-0;
  }
}
</style>
