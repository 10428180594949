<template>
  <div class="flex gap-4 select-none" >
    <!-- go start -->
    <div class="flex flex-col items-center">
      <Icon
        :style="{ opacity: canGoToStart && isEnabled ? 1 : 0.2 }"
        name="skip-back-line"
        class="cursor-pointer text-2xl"
        @click="$player.seek(0)" />
    </div>

    <!-- rewind -->
    <div class="flex flex-col items-center" >
      <Icon
        :style="{ opacity: canGoBackward && isEnabled ? 1 : 0.2 }"
        name="rewind-line"
        class="cursor-pointer text-2xl"
        @click="goBackward" />

      <div :class="textSize">-{{ step }}s</div>
    </div>

    <!-- play / pause -->
    <div :class="{ 'disabled': !isEnabled }">
      <Icon
        v-show="!isPlaying"
        name="play-fill"
        class="cursor-pointer text-4xl"
        @click="isPlaying = isEnabled" />
      <Icon
        v-show="isPlaying"
        name="pause-fill"
        class="cursor-pointer text-4xl"
        @click="isPlaying = false" />
    </div>

    <!-- forward -->
    <div class="flex flex-col items-center">
      <Icon
        :style="{ opacity: canGoForward && isEnabled ? 1 : 0.2 }"
        name="speed-line"
        class="cursor-pointer text-2xl"
        @click="goForward" />

      <div :class="textSize">+{{ step }}s</div>
    </div>

    <!-- go end -->
    <div class="flex flex-col items-center">
      <Icon
      :style="{ opacity: canGoToEnd && isEnabled ? 1 : 0.2 }"
        name="skip-forward-line"
        class="cursor-pointer text-2xl"
        @click="goToEnd" />
    </div>
  </div>
</template>

<script lang="ts" setup>
const {
    isPlaying,
    live,
    step,
    canGoForward,
    canGoToStart,
    canGoToEnd,
    canGoBackward,
    isEnabled
  } = usePlayerState()
  
const { $player } = useNuxtApp()

function goToEnd() {
  if (live.value) 
    $player.seek(live.value - 0.001)
  else
    $player.seek(1)
}

function goForward() {
  if (!canGoForward.value) return
  $player.forward(step.value)
}

function goBackward() {
  if (!canGoBackward.value) return
  $player.rewind(step.value)
}

const props = withDefaults(
  defineProps<{
    textSize?: string
  }>(),
  {
    textSize: 'text-base'
  }
)
</script>

<style scoped>

.disabled {
  opacity: 0.2;
}

</style>
