import { Settings } from 'luxon'

export default defineNuxtPlugin((nuxtApp) => {
  // set luxon locale initially
  Settings.defaultLocale = nuxtApp.$i18n.locale.value

  // and on locale change
  nuxtApp.$i18n.onBeforeLanguageSwitch = (oldLocale, newLocale) => {
    Settings.defaultLocale = newLocale
  }
})
