<!--

SidePanel
------------
A panel that appears on the left, on mobile.
The inner content can be provided within the <slot>

Props
- visible (default, false) : show/hide the panel
- left (default, true) : is the panel on the left ? Otherwise, appears from the right

-->
<template>
  <div v-show="visible" :class="[
    'sidepanel fixed top-0 left-0 z-[200] h-full w-full overscroll-contain',
    { 'panel-left': left, 'panel-right': !left }
  ]">
    <!-- panel content -->
    <Transition name="slide" @after-leave="leave">
      <div v-show="contentVisible" :class="[
        'absolute top-0 flex h-full w-full flex-col justify-between bg-neutral-800 px-10 pt-20 pb-10 text-neutral-700',
        { 'right-0': !left }
      ]">
        <button :class="['absolute top-6', { 'right-10': left, 'left-10': !left }]" @click="close">
          <Icon name="close-line" class="text-2xl text-white" />
        </button>

        <div class="flex h-full flex-col">
          <div class="mb-5 flex-grow overflow-auto">
            <slot :close="close" />
          </div>
          <div>
            <slot name="footer" :close="close" />
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    visible?: boolean
    left?: boolean
  }>(),
  {
    visible: false,
    left: true
  }
)

const emit = defineEmits(['update:visible'])

const contentVisible = ref(false)

// triggered by UI
const close = () => {
  contentVisible.value = false
}

defineExpose({
  close
})

// triggered when close animation is done
const leave = () => {
  useScroll().start()

  emit('update:visible', false)
}

watch(
  () => props.visible,
  () => {
    if (!props.visible) return

    // when panel is about to be shown
    nextTick(() => {
      useScroll().stop()

      // triggers the transitions on next tick
      contentVisible.value = props.visible
    })
  }
)

onMounted(() => {
  useScroll().start()
})
</script>

<style scoped>
/* Slide animation, for the panel */
.panel-left .slide-enter-from,
.panel-left .slide-leave-to {
  transform: translateX(-100%);
}

.slide-enter-to,
.slide-leave-from {
  transform: translateX(0);
}

.panel-right .slide-enter-from,
.panel-right .slide-leave-to {
  transform: translateX(100%);
}

.slide-enter-active,
.slide-leave-active {
  transition: 0.2s ease-in-out;
}
</style>
