/**
 * Nuxt-img provider for Directus
 */

import { joinURL, encodeQueryItem, encodePath, withBase } from 'ufo'
import { createOperationsGenerator } from '@nuxt/image-edge/dist/runtime/utils/index'

const operationsGenerator = createOperationsGenerator({
  keyMap: {
    width: 'width',
    height: 'height',
    fit: 'fit',
    format: 'format',
    quality: 'quality',
    withoutEnlargement: 'withoutEnlargement',
  },
  valueMap: {
    fit: {
      inside: 'inside',
      outside: 'outside',
      cover: 'cover',
      contain: 'contain'
    },
    format: {
      jpeg: 'jpg'
    }
  },
  joinWith: '&',
  formatter: (key: string, val: string) => encodeQueryItem(key, val)
})

export const getImage = (
  src: string,
  { modifiers = {}, baseURL = '/' } = {}
) => {
  const params = operationsGenerator(modifiers)

  return {
    url: withBase(
      joinURL('/assets/', encodePath(src) + (params ? '?' + params : '')),
      baseURL
    )
  }
}
