<template>
  <div class="border-b border-neutral-800 dt:border-0">
    <!-- top navigation -->
    <div class="flex h-24 w-full items-center justify-between p-4 dt:hidden">
      <NuxtLink
        :to="localePath({ name: 'index' })"
        class="block h-full w-auto"
        @click="searchPanelVisible = false">
        <NuxtImg
          src="/logo_white_horizontal.svg"
          sizes="sm:100px md:200px"
          width="200"
          height="112"
          alt="BOA logo"
          class="h-full w-auto" />
      </NuxtLink>
      <div class="flex gap-2">
        <NuxtLink :to="localePath({ name: 'search' })">
          <SquareButton
            icon="search-line"
            :aria-label="$t('Navbar.search')" />
        </NuxtLink>
        <SquareButton
          icon="menu-2-line"
          :aria-label="$t('Navbar.menu')"
          @click="panelVisible = true" />
      </div>
    </div>
  </div>

  <!-- sub links -->
  <HomeNavigation />

  <!-- burger menu, panel appears from the left -->
  <SidePanel
    v-model:visible="panelVisible"
    :left="true">
    <template #default="{ close }">
      <WhatSongModule
        class="mb-10"
        @click="close" />

      <SideMenu
        class="mb-6"
        @navigate="close" />
      <div class="flex w-full justify-start">
        <LocaleSwitcher :open-above="true" />
      </div>
    </template>
    <template #footer="{ close }">
      <!-- footer links -->
      <div class="flex justify-center gap-2 text-xs text-neutral-400">
        <NuxtLink
          :to="localePath({ name: 'legal' })"
          @click="close">
          {{ $t('Navbar.legal-terms') }}
        </NuxtLink>
        -
        <a
          href="https://lumy.bzh"
          target="_blank">
          {{ $t('Navbar.powered-by-lumy') }}
        </a>
      </div>
    </template>
  </SidePanel>
</template>

<script lang="ts" setup>
const localePath = useLocalePath()

// const {
//   searchPanelVisible,
//   fetchData: fetchSearchData,
//   initialize: initializeSearch
// } = useNavbarSearchPanel()
const searchPanelVisible = ref(true)

const panelVisible = ref(false)

// await fetchSearchData()
// initializeSearch()

// watch(useRoute(), initializeSearch)
</script>
