<template>
  <div
    v-show="visible"
    ref="panel"
    class="playerpanel fixed left-0 top-0 z-[100] h-full w-full overscroll-contain">
    <img
      ref="coverBack"
      :src="useImagePlaceholder('square')"
      class="absolute top-0 h-full w-full object-cover object-center"
      @load="
        () =>
          onCoverBackLoaded(coverFront, () => {
            coverFront.src = coverBack.src
          })
      " />
    <img
      ref="coverFront"
      :src="useImagePlaceholder('square')"
      class="absolute top-0 h-full w-full object-cover object-center"
      @load="() => onCoverFrontLoaded(coverFront)" />
    <div class="absolute top-0 h-full w-full bg-cover bg-center">
      <div
        class="relative flex h-full w-full flex-col justify-center bg-neutral-800/50 p-6 backdrop-blur-2xl">
        <div
          ref="swipeDetectZone"
          class="absolute left-0 top-0 z-10 h-2/3 w-full" />

        <div class="z-1 relative">
          <Player
            v-if="contentVisible"
            @forward="$player.forward(10)"
            @rewind="$player.rewind(10)" />
        </div>

        <div
          class="absolute bottom-0 left-1/2 hidden -translate-x-1/2 transform cursor-pointer rounded-t-full bg-black px-4 pb-0 pt-1 h-xs:block"
          @click="close">
          <Icon
            name="arrow-down-s-line"
            class="text-2xl" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap'

const props = withDefaults(
  defineProps<{
    visible?: boolean
  }>(),
  {
    visible: false
  }
)

const { cover } = usePlayerState()
const { initAnimation, callback: onSwipe } = useSwipeAnimation()

const emit = defineEmits(['update:visible'])

const panel = ref()
const swipeDetectZone = ref()
const contentVisible = ref(false)

const open = () => {
  nextTick(() => {
    contentVisible.value = true
  })

  gsap.fromTo(
    panel.value,
    {
      y: '100%'
    },
    {
      y: 0,
      ease: 'expo.out',
      onComplete: afterOpen
    }
  )
}

const close = () => {
  gsap.to(panel.value, {
    y: '100%',
    ease: 'expo.out',
    onComplete: afterClose
  })
}

let instance: Draggable[]
const afterOpen = () => {
  // prevent body scroll
  useScroll().stop()

  // init swipe animation
  instance = initAnimation(panel.value, swipeDetectZone.value, 'y', {
    minY: 0,
    maxY: 500
  })
  onSwipe.value = close
}

const afterClose = () => {
  // enable body scroll again
  useScroll().start()

  contentVisible.value = false

  // kill drag animation
  instance?.[0]?.kill()

  emit('update:visible', false)
}

watch(
  () => props.visible,
  () => {
    if (!props.visible) return

    open()
  }
)

onMounted(() => {
  // when route changes, make sure we don't have scroll
  document.body.classList.remove('no-scroll')
})

/**
 * Handle background cover transitions
 */
const coverBack = ref()
const coverFront = ref()

const { onCoverBackLoaded, onCoverFrontLoaded } = useImageBlending()

watch(
  cover,
  () => {
    if (!cover.value.src) {
      return
    }

    if (!coverFront.value?.src) {
      coverFront.value.src = cover.value.src
    }

    coverBack.value.src = cover.value.src
  },
  { deep: true }
)
</script>
