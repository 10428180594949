<template>
  <div
    class="fixed right-4 top-14 z-[1000] flex w-[300px] flex-col gap-4 transition-all duration-200">
    <Toast
      v-for="toast in toasts"
      :key="toast.id"
      :id="toast.id"
      :level="toast.level"
      @done="kill(toast.id)">
      {{ toast.message }}
    </Toast>
  </div>
</template>

<script lang="ts" setup>
const { toasts, kill } = useToast()
</script>
