enum ToastLevel {
  Info = 0,
  Warning = 1,
  Error = 2
}

interface Toast {
  id: number
  level: ToastLevel
  message: string
}

const id = ref(0)

const toasts: Ref<Toast[]> = ref([])

const toast = (message: string, level: ToastLevel = ToastLevel.Error) => {
  toasts.value.push({ id: id.value, message, level })
  id.value++
}

const kill = (id: number) => {
  toasts.value = toasts.value.filter((t: Toast) => t.id !== id)
}

export const useToast = () => {
  return {
    toast,
    kill,
    toasts
  }
}
