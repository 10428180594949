<template>
  <div
    v-if="isLive && !isLiveDeferred"
    class="h-2 w-2 shrink-0 animate-pulse rounded-full bg-red"
    :title="$t('PlayerLiveDot.you-listen-live')" />
  <div
    v-if="isLive && isLiveDeferred"
    class="border-neutral-300/50 h-2 w-2 shrink-0 rounded-full border bg-white/0"
    :title="$t('PlayerLiveDot.you-listen-live-deferred')" />
</template>

<script lang="ts" setup>
const { isLive, isLiveDeferred } = usePlayerState()
</script>
