<template>
  <ClientOnly>
    <HST
      v-if="main"
      :text="main" />
  </ClientOnly>
</template>

<script lang="ts" setup>
const {
  isLive,
  title,
  isProgram,
  programName,
  isLiveDeferred,
  liveDeferred,
  radioLabel
} = usePlayerState()

const { t } = useI18n()

const main = ref<null | string>(null)

watchEffect(() => {
  // live
  if (isLive.value) {
    if (isLiveDeferred.value) {
      main.value = [
        radioLabel.value,
        t('Player.deferred-from', { x: useFormatDuration(liveDeferred.value) })
      ].join(' ')
      return
    }

    // program
    if (isProgram.value) {
      main.value = title.value
      return
    }

    if ((title.value || '').includes('C-Lab')) {
      main.value = title.value
      return
    }

    // no program
    main.value = title.value.split('-')[0]
    return
  }
  // podcast
  else {
    main.value = `${programName.value}`
    return
  }
})
</script>
