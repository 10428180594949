<template>
  <div class="grid grid-cols-1 dt:grid-cols-5">
    <div class="col-span-1 border-r border-neutral-800">
      <Sidebar class="hidden dt:flex sticky top-0 h-[calc(100vh-100px)] overflow-auto custom-scroll" />
    </div>

    <div class="relative col-span-4 flex flex-col pb-24">
      <Navbar />

      <div class="min-h-[50vw] flex-grow">
        <slot />
      </div>

      <Footer />

      <!-- use the modal with useModal composable -->
      <Modal />

      <Toaster />
    </div>
  </div>

  <PlayerSmall />
</template>

<script setup lang="ts">
import 'remixicon/fonts/remixicon.css'

const { radio: playerRadio } = usePlayerState()

playerRadio.value = 'boa'

// used for overlay scrollbar
useHead({
  htmlAttrs: {
    'data-overlayscrollbars-initialize': ''
  },
  link: [
    {
      rel: 'manifest',
      href: '/manifest.json'
    }
  ]
})
</script>
