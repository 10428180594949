<template>
  <!-- need two blocks because of https://stackoverflow.com/q/16938437 -->
  <component :is="type === 'a' ? NuxtLink : 'button'" :aria-label="label" :class="[
    'relative z-0 inline-flex overflow-hidden rounded-[18px] border !outline-none',
    {
      'bg-neutral-800': !dark,
      'border-neutral-800': !whiteBorder,
      'border-white': whiteBorder,
      'pointer-events-none': disabled
    }
  ]">
    <div :class="[
      'relative z-0 inline-flex w-full items-center gap-1.5 rounded-2xl px-5 py-2',
      flexJustify,
      {
        'bg-neutral-800 text-white': dark,
        'bg-white text-white ': !dark,
        '!text-neutral-700': disabled,
        animated,
        'before:bg-white': animated && dark,
        'before:bg-neutral-800': animated && !dark
      },
      extraClass
    ]">
      <Icon v-if="leftIcon" :name="leftIcon" class="text-xl mix-blend-difference" />

      <span :class="['mix-blend-difference', labelExtraClass]">{{ label }}</span>

      <Icon v-if="rightIcon" :name="rightIcon" class="text-xl mix-blend-difference" />
    </div>
  </component>
</template>

<script lang="ts" setup>
const NuxtLink = resolveComponent('NuxtLink')

withDefaults(
  defineProps<{
    label: string
    type?: 'button' | 'a'
    leftIcon?: string | null
    rightIcon?: string | null
    dark?: boolean
    flexJustify?: string
    disabled?: boolean
    animated?: boolean
    whiteBorder?: boolean
    extraClass?: string
    labelExtraClass?: string
  }>(),
  {
    type: 'button',
    leftIcon: null,
    rightIcon: null,
    dark: true,
    flexJustify: 'justify-center',
    disabled: false,
    animated: true,
    whiteBorder: false
  }
)
</script>

<style scoped>
.animated::before {
  content: '';
  @apply absolute left-0 top-full h-full w-full transition duration-200 ease-in-out;
}

.animated:hover::before {
  @apply -translate-y-full transform;
}
</style>
