export const localeCodes = ["fr","br","ga","en"]

export const localeMessages = {
  "fr": [{ key: "../lang/fr.json", load: () => import("../lang/fr.json" /* webpackChunkName: "lang_fr_json_fr_json" */) }],
  "br": [{ key: "../lang/br.json", load: () => import("../lang/br.json" /* webpackChunkName: "lang_br_json_br_json" */) }],
  "ga": [{ key: "../lang/ga.json", load: () => import("../lang/ga.json" /* webpackChunkName: "lang_ga_json_ga_json" */) }],
  "en": [{ key: "../lang/en.json", load: () => import("../lang/en.json" /* webpackChunkName: "lang_en_json_en_json" */) }],
}

export const additionalMessages = Object({"fr":[],"br":[],"ga":[],"en":[],})

export const resolveNuxtI18nOptions = async (context) => {
  const nuxtI18nOptions = Object({})
  nuxtI18nOptions.experimental = Object({"jsTsFormatResource":false})
  nuxtI18nOptions.compilation = Object({"strictMessage":false,"escapeHtml":false})
 const vueI18nConfigLoader = async (loader) => {
            const config = await loader().then(r => r.default || r)
            return typeof config === 'object'
              ? config
              : typeof config === 'function'
                ? await config()
                : {}
          }
  nuxtI18nOptions.vueI18n = Object({})
  nuxtI18nOptions.locales = [Object({"code":"fr","iso":"fr-FR","file":"fr.json","hash":"a559b3b0","type":"static"}),Object({"code":"br","iso":"br-BR","file":"br.json","hash":"d480e15a","type":"static"}),Object({"code":"ga","iso":"ga-GA","file":"ga.json","hash":"ff65a1ac","type":"static"}),Object({"code":"en","iso":"en-EN","file":"en.json","hash":"ad5dad6b","type":"static"})]
  nuxtI18nOptions.defaultLocale = "fr"
  nuxtI18nOptions.defaultDirection = "ltr"
  nuxtI18nOptions.routesNameSeparator = "___"
  nuxtI18nOptions.trailingSlash = false
  nuxtI18nOptions.defaultLocaleRouteNameSuffix = "default"
  nuxtI18nOptions.strategy = "prefix"
  nuxtI18nOptions.lazy = true
  nuxtI18nOptions.langDir = "lang"
  nuxtI18nOptions.rootRedirect = null
  nuxtI18nOptions.detectBrowserLanguage = Object({"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true})
  nuxtI18nOptions.differentDomains = false
  nuxtI18nOptions.baseUrl = ""
  nuxtI18nOptions.dynamicRouteParams = false
  nuxtI18nOptions.customRoutes = "config"
  nuxtI18nOptions.pages = Object({"[radio]/programs":Object({"fr":"/[radio]/emissions"}),"programs/index":Object({"fr":"/emissions"}),"programs/[category]":Object({"fr":"/emissions/[category]"}),"schedule":Object({"fr":"/programmation"}),"partner-radios":Object({"fr":"/les-radios-partenaires"}),"what-song":Object({"fr":"/c-etait-quoi-ce-morceau"}),"about":Object({"fr":"/a-propos"}),"legal":Object({"fr":"/mentions-legales"}),"events/index":Object({"fr":"/evenements"}),"events/[id]":Object({"fr":"/evenement/[id]"}),"news/index":Object({"fr":"/actualites"}),"news/[id]":Object({"fr":"/actualite/[id]"})})
  nuxtI18nOptions.skipSettingLocaleOnNavigate = false
  nuxtI18nOptions.types = "composition"
  nuxtI18nOptions.debug = false
  return nuxtI18nOptions
}

export const nuxtI18nOptionsDefault = Object({experimental: Object({"jsTsFormatResource":false}),compilation: Object({"strictMessage":true,"escapeHtml":false}),vueI18n: "",locales: [],defaultLocale: "",defaultDirection: "ltr",routesNameSeparator: "___",trailingSlash: false,defaultLocaleRouteNameSuffix: "default",strategy: "prefix_except_default",lazy: false,langDir: null,rootRedirect: null,detectBrowserLanguage: Object({"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true}),differentDomains: false,baseUrl: "",dynamicRouteParams: false,customRoutes: "page",pages: Object({}),skipSettingLocaleOnNavigate: false,types: "composition",debug: false})

export const nuxtI18nInternalOptions = Object({__normalizedLocales: [Object({"code":"fr","iso":"fr-FR","file":"fr.json","hash":"a559b3b0","type":"static"}),Object({"code":"br","iso":"br-BR","file":"br.json","hash":"d480e15a","type":"static"}),Object({"code":"ga","iso":"ga-GA","file":"ga.json","hash":"ff65a1ac","type":"static"}),Object({"code":"en","iso":"en-EN","file":"en.json","hash":"ad5dad6b","type":"static"})]})
export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const isSSG = false
