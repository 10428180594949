<template>
  <div
    ref="container"
    class="relative h-1 w-20 shrink-0">
    <div
      class="absolute left-0 top-0 z-[1] h-5 w-full -translate-y-1/2 transform"
      @click="onClick"></div>
    <!-- grey bar -->
    <div class="absolute left-0 h-full w-full rounded-xl bg-white/30"></div>

    <!-- volume bar, in white -->
    <div
      ref="whiteBar"
      class="absolute left-0 top-0 h-full w-1 rounded-xl bg-white"></div>

    <!-- a bigger transparent cursor around the white dot for better UX -->
    <div
      ref="cursor"
      class="absolute -left-[20px] top-1/2 z-[2] h-10 w-10 -translate-y-1/2 transform rounded-full">
      <div
        class="absolute left-[calc(50%-4px)] top-1/2 h-2 w-2 -translate-y-1/2 transform rounded-full bg-white"></div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import _ from 'lodash'
import gsap from 'gsap'

import Draggable from 'gsap/Draggable'

// transition duration for animations, in seconds
const DURATION = 0

const container = ref()
const cursor = ref()

const whiteBar = ref()

const { volume } = usePlayerState()

let instance: Draggable[]
const isDragged = ref(false)
const initDraggable = () => {
  if (!cursor.value) return

  gsap.registerPlugin(Draggable)

  instance = Draggable.create(cursor.value, {
    type: 'x',
    bounds: {
      minX: 0,
      maxX: container.value.getBoundingClientRect()?.width
    },
    zIndexBoost: false,
    onDragStart: function () {
      isDragged.value = true
    },
    onDragEnd: function () {
      isDragged.value = false
    },
    onDrag: function () {
      setCursorPosition(this.x)
      setBarWidth(whiteBar.value, this.x)

      onUserProgressUpdate()
    }
  })
}

const init = () => {
  instance?.[0]?.kill()

  initDraggable()

  setWhiteBar()
}

const initThrottled = _.throttle(init, 50)

onMounted(() => {
  init()

  // reset animation on screen resize
  window.addEventListener('resize', initThrottled)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', initThrottled)
})

const onClick = (event) => {
  const targetProgress = event.offsetX

  setCursorPosition(targetProgress)
  setBarWidth(whiteBar.value, targetProgress)
  onUserProgressUpdate()
}

const setBarWidth = (element: HTMLElement, x: number) => {
  if (!element) return

  gsap.to(element, {
    duration: DURATION,
    width: x
  })
}

const setCursorPosition = (x: number) => {
  if (!cursor.value) return
  gsap.to(cursor.value, { duration: DURATION, x })
}

const onUserProgressUpdate = () => {
  const _progress =
    (gsap.getProperty(cursor.value, 'x') as number) /
    container.value.getBoundingClientRect()?.width

  volume.value = _.round(_progress, 2)
}

const setWhiteBar = () => {
  const width = container.value.getBoundingClientRect()?.width
  setBarWidth(whiteBar.value, volume.value * width)
  setCursorPosition(volume.value * width)
}

watch(volume, setWhiteBar)
</script>
