/**
 * Return current route base name
 * Because the use of i18n module, useRoute().name does not return only the route name
 * but also the locale, for example 'search___fr'
 * This composable will return 'search' in this case
 * @returns
 */
export const useRouteName = () => {
  return useRouteBaseName()(useRoute())
}
