<template>
  <ClientOnly>
    <HST
      class="block text-xs font-bold text-neutral-700"
      v-if="second && !isProgram"
      :text="second" />
  </ClientOnly>
</template>

<script lang="ts" setup>
const { isLive, title, programName, isProgram, isLiveDeferred } =
  usePlayerState()

const second = ref<null | string>(null)

watchEffect(() => {
  // live
  if (isLive.value) {
    if (isLiveDeferred.value) {
      second.value = ''
      return
    }

    if ((title.value || '').includes('C-Lab')) return

    return (second.value = title.value.split('-')[1])
  }
  // podcast
  else {
    return (second.value = title.value)
  }
})
</script>
