<template>
  <div class="w-full bg-neutral-800">
    <LinkList
      class="mb-6 text-lg"
      :items="mainLinks"
      @navigate="emit('navigate')" />

    <h3 class="mb-3 text-left text-xs uppercase text-neutral-600">
      {{ $t('SideMenu.boa-radio-and-partners') }}
    </h3>
    <LinkList
      class="mb-6 text-lg"
      :items="boaLinks"
      @navigate="emit('navigate')" />

    <h3 class="mb-3 text-left text-xs uppercase text-neutral-600">
      {{ $t('SideMenu.stay-in-touch') }}
    </h3>
    <LinkList
      class="text-lg"
      :items="stayInTouchLinks"
      @navigate="emit('navigate')" />
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()
const localePath = useLocalePath()

const emit = defineEmits(['navigate'])

const mainLinks = computed(() => [
  {
    icon: 'home-2-line',
    title: t('SideMenu.home'),
    path: localePath({ name: 'index' })
  },
  {
    icon: 'search-line',
    title: t('SideMenu.search'),
    path: localePath({
      name: 'search'
    })
  },
  {
    icon: 'headphone-line',
    title: t('SideMenu.programs'),
    path: localePath({ name: 'programs' })
  },
  {
    icon: 'calendar-event-line',
    title: t('SideMenu.program-schedule'),
    path: localePath({ name: 'schedule' })
  },
  {
    icon: 'radio-line',
    title: t('SideMenu.about'),
    path: localePath({ name: 'about' })
  }
])

const boaLinks = computed(() => [
  {
    icon: 'newspaper-line',
    title: t('SideMenu.news'),
    path: localePath({ name: 'news' })
  },
  {
    icon: 'coupon-2-line',
    title: t('SideMenu.events'),
    path: localePath({ name: 'events' })
  },
  {
    icon: 'radio-line',
    title: t('SideMenu.all-partner-radios'),
    path: localePath({ name: 'partner-radios' })
  }
])

const stayInTouchLinks = computed(() => [
  {
    icon: 'mail-line',
    title: t('SideMenu.contact'),
    path: localePath({ name: 'contact' })
  }
  /*  {
    icon: 'notification-2-line',
    title: t('SideMenu.subscribe-newsletter'),
    path: localePath({ name: 'index', hash: '#newsletter' })
  } */
])
</script>
