<template>
  <div>
    <div ref="container" :class="[
      'fixed z-40 w-full px-8',
      ['index', 'partner-radios'].includes(useRouteName())
        ? 'bottom-20'
        : 'bottom-8'
    ]">
      <!-- progress bar -->
      <PlayerProgressBar v-model:progress="progress" v-model:live="live" v-model:loading="loading"
        :show-current-duration="false" />
      <div ref="swipeDetectZone"
        class="flex w-full items-center gap-4 rounded-b-xl bg-neutral-800/60 p-4 backdrop-blur-lg">
        <!-- cover -->
        <PlayerCover :cover-width="50" cover-sizes="sm:100px" />

        <!-- content title + radio name -->
        <div class="min-w-0 flex-grow" @click="panelVisible = true">
          <PlayerMainTitle />

          <div class="flex flex-wrap items-center gap-x-1">
            {{ radioLabel }}
          </div>
        </div>
        <!-- controls -->
        <Icon :style="{ opacity: isEnabled ? 1 : 0.2 }" v-show="!isPlaying" name="play-fill"
          class="cursor-pointer text-4xl" @click="isPlaying = isEnabled" />
        <Icon :style="{ opacity: isEnabled ? 1 : 0.2 }" v-show="isPlaying" name="pause-fill"
          class="cursor-pointer text-4xl" @click="isPlaying = false" />
      </div>
    </div>
    <PlayerPanel v-model:visible="panelVisible" />
  </div>
</template>

<script lang="ts" setup>
import gsap from 'gsap'

const { progress, live, loading, isPlaying, radioLabel, isEnabled } =
  usePlayerState()

const { initAnimation, callback: onSwipe } = useSwipeAnimation()

const container = ref()
const swipeDetectZone = ref()
const panelVisible = ref(false)

let instance: Draggable[]
onMounted(() => {
  // init swipe animation
  // instance = initAnimation(container.value, swipeDetectZone.value, 'x', {
  //   minX: -500,
  //   maxX: 500
  // })
  // onSwipe.value = (delta: number) => {
  //   close(delta > 0)
  // }
})

// close the player by sliding it to a side, default on the left
const close = (left: boolean = true) => {
  gsap.to(container.value, {
    x: left ? '100%' : '-100%',
    ease: 'expo.out',
    onComplete: afterClose
  })
}

const afterClose = () => {
  // kill drag animation
  instance?.[0]?.kill()
}
</script>
