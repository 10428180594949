/**
 * Used to make transition between two images
 */
import gsap from 'gsap'

const onCoverBackLoaded = (
  coverFront: HTMLImageElement,
  onComplete: gsap.Callback
) => {
  gsap.fromTo(
    coverFront,
    {
      opacity: 1
    },
    {
      duration: 0.4,
      opacity: 0,
      onComplete
    }
  )
}

const onCoverFrontLoaded = (coverFront: HTMLImageElement) => {
  gsap.set(coverFront, {
    opacity: 1
  })
}

export const useImageBlending = () => {
  return {
    onCoverBackLoaded,
    onCoverFrontLoaded
  }
}
