/**
 * An helper to know the current screen size
 */

import _ from 'lodash'

const DESKTOP_BREAKPOINT = 992
const TABLET_BREAKPOINT = 768

const isMobile = ref(false)
const isDesktop = computed(() => !isMobile.value)

// for now, can be mobile and tablet or desktop and tablet
const isTablet = ref(false)

const isDesktopOrTablet = computed(() => !isMobile.value || isTablet.value)

const isInitialized = ref(false)

const update = () => {
  isMobile.value = window.innerWidth < DESKTOP_BREAKPOINT
  isTablet.value =
    window.innerWidth > TABLET_BREAKPOINT &&
    window.innerWidth < DESKTOP_BREAKPOINT
}

export const useScreen = () => {
  if (!process.server && !isInitialized.value) {
    update()
    window.addEventListener('resize', _.throttle(update, 50))
    isInitialized.value = true
  }

  return { isDesktop, isMobile, isTablet, isDesktopOrTablet }
}
