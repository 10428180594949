export default defineNuxtPlugin(async () => {
  const config = useRuntimeConfig()

  const { data } = await useFetch(
    [config.public.apiBaseURL, 'items', 'radio'].join('/'),
    {
      params: {
        filter: JSON.stringify({
          group: {
            group_id: {
              _eq: 1
            }
          }
        }),
        limit: -1
      },
      transform: (data) => {

        return data.data
        .sort(
          (r: any) => r.azuracast_station_id != 'boa' ? 1 : -1
        )
        .map(
          (r: any) => ({
            id: r.id,
            key: r.azuracast_station_id,
            label: r.name,
            image: {
              src: r.logo,
              alt: r.name,
              provider: 'directus'
            }
          })
        )
      }
    }
  )

  if (!data.value) {
    console.error('cannot fetch radios')
    return
  }
  
  const radios = data.value

  // radios.push({
  //   key: 'lumy',
  //   label: 'Lumy Underground (test dev)',
  //   image: {
  //     src: '',
  //     alt: 'Lumy Underground'
  //   }
  // })

  return {
    provide: {
      radios,
      radioById: (id: number) => radios.find((r) => r.id === id)
    }
  }
})
