<template>
  <div
    ref="container"
    class="overflow-hidden">
    <div
      ref="marquee"
      class="inline-block whitespace-nowrap">
      <div
        ref="content"
        class="inline-flex"
        :style="{ paddingRight: gap + 'px' }">
        {{ text }}
      </div>
      <div
        :style="{ paddingRight: gap + 'px' }"
        :class="[isScrolling ? 'opacity-100' : 'opacity-0']"
        class="inline-flex">
        {{ text }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import _ from 'lodash'
import gsap from 'gsap'
import { set } from 'nuxt/dist/app/compat/capi'

const props = withDefaults(
  defineProps<{
    text: string
    speed?: number
  }>(),
  {
    speed: 0.2
  }
)
const container = ref<HTMLDivElement>()
const marquee = ref<HTMLDivElement>()
const content = ref<HTMLDivElement>()

const isScrolling = ref(true)
const gap = ref(20)

const containerWidth = ref(container.value?.getBoundingClientRect()?.width)
const marqueeWidth = ref(content.value?.getBoundingClientRect()?.width)

const loop = ref<gsap.core.Timeline>()
const tweens = ref<gsap.core.Tween[]>([])
onMounted(() => {
  if (!marquee.value) return
  const texts = gsap.utils.toArray(marquee.value.children)

  // Init animation
  loop.value = horizontalLoop(texts, {
    paused: true,
    repeat: -1,
    speed: (props.text.length * props.speed * 2) / 50
  })
  // reset animation on screen resize
  window.addEventListener('resize', initAnimationThrottled)
})
onBeforeUnmount(() => {
  window.removeEventListener('resize', initAnimationThrottled)
  // kill and remove previous animation
  loop.value?.clear()
  loop.value?.kill()
  tweens.value?.forEach((tween) => tween.kill())
})

watch([() => props.text, marquee], () => {
  // when text changes, reset animation
  initAnimation()
})

const initAnimation = () => {
  if (!process.client) return
  setTimeout(() => {
    if (!marquee.value) return

    const containerWidth = container.value?.getBoundingClientRect()?.width
    const marqueeWidth = content.value?.getBoundingClientRect()?.width
    if (!containerWidth || !marqueeWidth) return
    if (marqueeWidth > containerWidth) {
      loop.value?.progress(0, true).pause()
      loop.value?.play()
      return (isScrolling.value = true)
    } else {
      loop.value?.progress(0, true).pause()
      return (isScrolling.value = false)
    }
  }, 1)
}

const initAnimationThrottled = _.throttle(initAnimation, 50)

function horizontalLoop(items, config) {
  config = config || {}

  let tl = gsap.timeline({
      repeat: config.repeat,
      paused: config.paused,
      defaults: { ease: 'none' },
      onReverseComplete: () => tl.totalTime(tl.rawTime() + tl.duration() * 100)
    }),
    length = items.length,
    startX = items[0].offsetLeft,
    times = [],
    widths = [],
    xPercents = [],
    curIndex = 0,
    pixelsPerSecond = (config.speed || 1) * 100,
    snap = config.snap === false ? (v) => v : gsap.utils.snap(config.snap || 1), // some browsers shift by a pixel to accommodate flex layouts, so for example if width is 20% the first element's width might be 242px, and the next 243px, alternating back and forth. So we snap to 5 percentage points to make things look more natural
    totalWidth,
    curX,
    distanceToStart,
    distanceToLoop,
    item,
    i
  const tween = gsap.set(items, {
    // convert "x" to "xPercent" to make things responsive, and populate the widths/xPercents Arrays to make lookups faster.
    xPercent: (i, el) => {
      let w = (widths[i] = parseFloat(gsap.getProperty(el, 'width', 'px')))
      xPercents[i] = snap(
        (parseFloat(gsap.getProperty(el, 'x', 'px')) / w) * 100 +
          gsap.getProperty(el, 'xPercent')
      )
      return xPercents[i]
    }
  })

  const tween2 = gsap.set(items, { x: 0 })
  totalWidth =
    items[length - 1].offsetLeft +
    (xPercents[length - 1] / 100) * widths[length - 1] -
    startX +
    items[length - 1].offsetWidth *
      gsap.getProperty(items[length - 1], 'scaleX') +
    (parseFloat(config.paddingRight) || 0)
  for (i = 0; i < length; i++) {
    item = items[i]
    curX = (xPercents[i] / 100) * widths[i]
    distanceToStart = item.offsetLeft + curX - startX
    distanceToLoop =
      distanceToStart + widths[i] * gsap.getProperty(item, 'scaleX')
    tl.to(
      item,
      {
        xPercent: snap(((curX - distanceToLoop) / widths[i]) * 100),
        duration: distanceToLoop / pixelsPerSecond
      },
      0
    )
      .fromTo(
        item,
        {
          xPercent: snap(
            ((curX - distanceToLoop + totalWidth) / widths[i]) * 100
          )
        },
        {
          xPercent: xPercents[i],
          duration:
            (curX - distanceToLoop + totalWidth - curX) / pixelsPerSecond,
          immediateRender: false
        },
        distanceToLoop / pixelsPerSecond
      )
      .add('label' + i, distanceToStart / pixelsPerSecond)
    times[i] = distanceToStart / pixelsPerSecond
    tweens.value = [tween, tween2]
  }
  function toIndex(index, vars) {
    vars = vars || {}
    Math.abs(index - curIndex) > length / 2 &&
      (index += index > curIndex ? -length : length) // always go in the shortest direction
    let newIndex = gsap.utils.wrap(0, length, index),
      time = times[newIndex]
    if (time > tl.time() !== index > curIndex) {
      // if we're wrapping the timeline's playhead, make the proper adjustments
      vars.modifiers = { time: gsap.utils.wrap(0, tl.duration()) }
      time += tl.duration() * (index > curIndex ? 1 : -1)
    }
    curIndex = newIndex
    vars.overwrite = true
    return tl.tweenTo(time, vars)
  }
  tl.next = (vars) => toIndex(curIndex + 1, vars)
  tl.previous = (vars) => toIndex(curIndex - 1, vars)
  tl.current = () => curIndex
  tl.toIndex = (index, vars) => toIndex(index, vars)
  tl.times = times
  tl.progress(1, true).progress(0, true) // pre-render for performance
  if (config.reversed) {
    tl.vars.onReverseComplete()
    tl.reverse()
  }

  return tl
}
</script>
