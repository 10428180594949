<template>
  <div
    v-if="useRouteName() === 'index'"
    class="sticky top-0 z-20 border-b border-neutral-800 bg-neutral-900/90 p-2 backdrop-blur-md dt:border-0">
    <div
      ref="container"
      class="w-full overflow-hidden">
      <ul
        ref="containerInner"
        class="flex w-fit gap-2 md:w-full md:justify-center">
        <li
          v-for="(badge, index) in badges"
          :key="index">
          <NuxtLink :to="badge.path">
            <PrimaryBadge class="!text-base">{{ badge.title }}</PrimaryBadge>
          </NuxtLink>
        </li>
      </ul>
    </div>

    <SecondaryButton
      type="a"
      :to="localePath({ name: 'partner-radios' })"
      :label="$t('Navbar.see-partners')"
      class="absolute bottom-2 right-2 top-2 hidden border !border-neutral-500 !px-[10px] text-base !text-neutral-500 xl:flex" />
  </div>
</template>

<script lang="ts" setup>
import _ from 'lodash'
import gsap from 'gsap'
import Draggable from 'gsap/Draggable'

const localePath = useLocalePath()

const { t } = useI18n()

const badges = computed(() => [
  {
    title: t('HomeNavigation.schedule'),
    path: localePath({ name: 'schedule' })
  },
  {
    title: t('HomeNavigation.programs'),
    path: localePath({ name: 'programs' })
  },
  {
    title: t('HomeNavigation.news'),
    path: localePath({ name: 'news' })
  },
  {
    title: t('HomeNavigation.events'),
    path: localePath({ name: 'events' })
  },
  {
    title: t('HomeNavigation.sponsors'),
    path: localePath({ name: 'sponsors' })
  }
])

const init = () => {
  if (!containerInner.value || !container.value) {
    return
  }

  // check if there is an overflow of the navigation
  const overflow =
    containerInner.value.getBoundingClientRect().width -
    container.value.getBoundingClientRect().width

  if (overflow <= 0) {
    instance?.[0]?.kill()
    return
  }

  // reset position if needed on resize
  gsap.set(containerInner.value, { x: 0 })

  // if yes, create a draggable
  gsap.registerPlugin(Draggable)

  instance?.[0]?.kill()

  instance = Draggable.create(containerInner.value, {
    type: 'x',
    bounds: {
      minX: -overflow,
      maxX: 0
    },
    onDrag: function () {}
  })
}
const initThrottled = _.throttle(init, 10)

const container = ref()
const containerInner = ref()
let instance: Draggable[]
onMounted(() => {
  init()
  // refresh carousel on page resize, using debounce
  window.addEventListener('resize', initThrottled)
})

onBeforeUnmount(() => {
  instance?.[0]?.kill()
  window.removeEventListener('resize', initThrottled)
})
</script>
