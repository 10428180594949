<template>
  <div
    ref="toast"
    class="min-h-[10px] overflow-hidden rounded-lg bg-neutral-900">
    <div
      :class="[
        'px-4 py-2',
        {
          'bg-neutral-500 text-black': level == 0 || level == 1
        },
        {
          'bg-red/70': level == 2
        }
      ]">
      <div class="text-lg font-bold opacity-80">
        {{ $t(`Toast.title.${level}`) }}
      </div>
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
import gsap from 'gsap'

const props = withDefaults(
  defineProps<{
    id: number
    level: number
    delay?: number
  }>(),
  {
    delay: 3000
  }
)

const emit = defineEmits(['done'])

const toast = ref()

const enter = () => {
  gsap.fromTo(
    toast.value,
    {
      x: '100%'
    },
    {
      duration: 0.2,
      x: 0,
      easing: 'expo.out'
    }
  )
}

const leave = () => {
  gsap.fromTo(
    toast.value,
    {
      opacity: 1
    },
    {
      duration: 1,
      opacity: 0,
      onComplete: function () {
        emit('done')
      }
    }
  )
}

onMounted(() => {
  enter()

  setTimeout(leave, props.delay)
})
</script>
